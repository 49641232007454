/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useNavigate } from "react-router-dom";

import { useEffect } from "react";
import MaxWidthContainer from "../../../components/MaxWidthContainer";
import SignUpForm from "../../../components/forms/SignUpForm";
import Column from "../../../components/Column";
import Page from "../../../components/Page";

import "./SignUpPage.css";

// SignUp Page
function SignUpPage({ loggedInUser, userLoading }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    // If we're not still loading the user, and there
    // is a logged in user, then let's redirect
    // to their dashboard
    useEffect(() => {
        if (!userLoading && loggedInUser) {
            navigate(`/${loggedInUser?.displayName}`);
        }
    }, [loggedInUser, navigate, userLoading]);

    return (
        <Page className="SignUpPage">
            <MaxWidthContainer>
                <Column className="content">
                    <SignUpForm includeHeader includeFooter />
                </Column>
            </MaxWidthContainer>
        </Page>
    );
}

export default SignUpPage;
