/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { Outlet, useLocation } from "react-router-dom";
import { useMemo } from "react";

import Element from "../../components/Element";
import AnonymousHeader from "./AnonymousHeader";
import AnonymousFooter from "./AnonymousFooter";

import "./AnonymousLayout.css";

// Anonymous Layout

function AnonymousLayout({ className, ...props }) {
	const cn = className ? `AnonymousLayout ${className}` : "AnonymousLayout";

	const location = useLocation();

	const isLanding = useMemo(
		() => location.pathname === "/",
		[location?.pathname]
	);
	const showFooter = useMemo(() => !isLanding, [isLanding]);

	return (
		<Element {...props} className={cn}>
			<AnonymousHeader />
			<Outlet />
			{showFooter && <AnonymousFooter />}
		</Element>
	);
}

export default AnonymousLayout;
