/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import './Element.css';

// Element

function Element({
  className,
  children,
  mB,
  mT,
  mL,
  mR,
  pB,
  pT,
  pL,
  pR,
  color,
  ...props
}) {
  let cn = className ? `${className}` : 'Element';
  const Klass = props.as ? props.as : 'div';
  const propWidth = props.width ? props.width : null
  const propStyles = props.style ? props.style : null

  const { as, style, width, ...rest } = props;

  // Add margin
  if (mB) {
    cn += ` mB-${mB}`;
  }
  if (mT) {
    cn += ` mT-${mT}`;
  }
  if (mL) {
    cn += ` mL-${mL}`;
  }
  if (mR) {
    cn += ` mR-${mR}`;
  }

  // Add padding
  if (pB) {
    cn += ` pB-${pB}`;
  }
  if (pT) {
    cn += ` pT-${pT}`;
  }
  if (pL) {
    cn += ` pL-${pL}`;
  }
  if (pR) {
    cn += ` pR-${pR}`;
  }

  if (color) {
    cn += ` ${color}`
  }

  // Add width
  if (propStyles) {
    if (propWidth) {
        propStyles.width = propWidth
    }
  }

  return (
    <Klass className={cn} style={propStyles} {...rest}>
      {children}
    </Klass>
  );
}

export default Element;
