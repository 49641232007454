/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import { useEffect, useMemo, useState } from "react";

import { InstantSearch, SearchBox } from "react-instantsearch";
import { liteClient as algoliasearch } from "algoliasearch/lite";

import usePaginateCollection from "../../hooks/usePaginateCollection";

import MaxWidthContainer from "../../components/MaxWidthContainer";
import UserGrid from "../../components/UserGrid";
import Column from "../../components/Column";
import PeopleHeader from "./PeopleHeader";
import Page from "../../components/Page";
import Row from "../../components/Row";

import "./PeoplePage.css";
import UserHits from "../../components/UserHits";

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID;
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const USERS_INDEX = process.env.REACT_APP_USERS_INDEX;

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

// People Page
function PeoplePage() {
    const [showFirestoreUsers, setShowFirestoreUsers] = useState(true);
    const [showAlgoliaUsers, setShowAlgoliaUsers] = useState(false);

    // Get users from the Firestore
    const { results: firestoreUsers } = usePaginateCollection(
        "users",
        null,
        "displayName",
        "asc",
        36
    );
    const algoliaUsers = [];

    // Get the users
    const users = useMemo(() => firestoreUsers, [algoliaUsers, firestoreUsers]);

    const searchInput = document.getElementsByClassName("ais-SearchBox-input")[0];

    useEffect(() => {
        if (!showAlgoliaUsers && searchInput) {
            searchInput.focus();
            searchInput.select();
        }
    }, [showAlgoliaUsers, searchInput])

    // Query hook
    const queryHook = (query, search) => {
        if (query?.length > 3) {
            search(query);
            setShowFirestoreUsers(false);
            setShowAlgoliaUsers(true);
        } else {
            setShowFirestoreUsers(true);
            setShowAlgoliaUsers(false);
        }
    };

    return (
        <Page className="PeoplePage">
            <InstantSearch searchClient={searchClient} indexName={USERS_INDEX}>
                <MaxWidthContainer>
                    <Row
                        className="top"
                        width="100%"
                    >
                        <PeopleHeader />
                        <SearchBox
                            placeholder="Find an expert"
                            queryHook={queryHook}
                        />
                    </Row>
                </MaxWidthContainer>
                <MaxWidthContainer className="users-container">
                    <Column width="100%">
                        {showFirestoreUsers && (
                            <UserGrid
                                users={users}
                                avatarSize={100}
                                showFullName
                            />
                        )}
                        {showAlgoliaUsers && (
                            <UserHits />
                        )}
                    </Column>
                </MaxWidthContainer>
                <MaxWidthContainer className="btns-footer" />
            </InstantSearch>
        </Page>
    );
}

export default PeoplePage;
