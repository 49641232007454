/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Form, { Input } from "react-form-component";

import { useEffect, useState, useMemo } from "react";

import "../Forms.css";
import "./EditNameForm.css";
import Element from "../../Element";
import Label from "../../Label";
import Loading from "../../Loading";
import Button from "../../Button";
import {
	LOCAL_INVALID_FIRST_NAME,
	LOCAL_INVALID_LAST_NAME,
	handleError,
} from "../../../utils/errors";
import { updateUser } from "../../../clients/firebase";
import { toastIt } from "../../../utils/alerts";

// Edit Name Form

function EditNameForm({
	dark,
	className,
	onChange,
	onLoading,
	loggedInUser,
    loggedInUserProfile,
	buttonTitle = "Update name",
	...props
}) {
	let cn = className
		? `DEFormContainer EditNameForm ${className}`
		: "DEFormContainer EditNameForm";

	// Are we styling this dark?
	if (dark) cn += " dark";

	// User info
	const [firstName, setFirstName] = useState(loggedInUserProfile?.first_name);
	const [lastName, setLastName] = useState(loggedInUserProfile?.last_name);

	// UI state
	const [loading, setLoading] = useState(false);
	const validFirstName = useMemo(
		() => firstName && firstName.length > 1,
		[firstName]
	);
	const validLastName = useMemo(
		() => lastName && lastName.length > 1,
		[lastName]
	);

	// Effects
	useEffect(() => {
		if (typeof onChange === "function") {
			onChange(firstName, lastName);
		}
	}, [firstName, lastName, onChange]);

	useEffect(() => {
		if (loggedInUserProfile && loggedInUserProfile.first_name && !firstName) {
			setFirstName(loggedInUserProfile.first_name);
		}
		if (loggedInUserProfile && loggedInUserProfile.last_name && !lastName) {
			setLastName(loggedInUserProfile.last_name);
		}
	}, [loggedInUserProfile, firstName, lastName]);

	// Handlers
	const handleOnSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleOnUpdate = async () => {
		let didUpdate = false;
		const userProfile = loggedInUserProfile;
		if (firstName !== userProfile?.first_name || lastName !== userProfile?.last_name) {
			setLoading(true);
			if (firstName && !validFirstName) {
				handleError({ message: LOCAL_INVALID_FIRST_NAME });
			}
			if (lastName && !validLastName) {
				handleError({ message: LOCAL_INVALID_LAST_NAME });
			}
			if (validFirstName || validLastName) {
				// Update
				const updates = {};
				if (firstName && validFirstName) updates.first_name = firstName;
				if (lastName && validLastName) updates.last_name = lastName;
				const res = await updateUser(userProfile.uid, updates);
				if (res) {
					didUpdate = true;
				} else {
					handleError();
				}
			}
			setLoading(false);
		}
        if (didUpdate) {
            toastIt("Update successful", "success")
        }
		return didUpdate;
	};

	return (
		<Element {...props} className={cn}>
			<Form fields={["first", "last"]} onSubmit={handleOnSubmit}>
				<Input
					name="first"
					label="First"
                    initialValue={firstName}
					className={
						firstName && firstName.length > 0
							? "FloatingInput EmailInput filled"
							: "FloatingInput EmailInput"
					}
					onChange={(val) => setFirstName(val)}
				/>
				<Input
					name="last"
					label="Last"
                    initialValue={lastName}
					className={
						lastName && lastName.length > 0
							? "FloatingInput filled"
							: "FloatingInput"
					}
					onChange={(val) => setLastName(val)}
				/>
				<Button
					className="continue light"
					onClick={() => {
						handleOnUpdate();
					}}
				>
					{loading && <Loading />}
					{!loading && <Label>{buttonTitle}</Label>}
				</Button>
			</Form>
		</Element>
	);
}

export default EditNameForm;
