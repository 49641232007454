/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports
import MaxWidthContainer from '../../components/MaxWidthContainer';
import Column from '../../components/Column';
import AddBookModal from './AddBookModal';
import Page from '../../components/Page';

import './AddBookPage.css';

// Add Book Page
function AddBookPage() {
  return (
    <Page className="AddBookPage">
      <MaxWidthContainer>
        <Column>
          <AddBookModal />
        </Column>
      </MaxWidthContainer>
    </Page>
  );
}

export default AddBookPage;
