/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from '../Element';

import './Page.css';

// Page

function Page({ children, className }) {
  const cn = className ? `Page ${className}` : 'Page';

  return (
    <Element className={cn}>
      {children}
    </Element>
  );
}

export default Page;
