/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useNavigate } from "react-router-dom";
import Element from "../../../components/Element";
import EmptyBook from "./EmptyBook";

import "./EmptyBookGrid.css"

// Empty Book Grid
function EmptyBookGrid({ isMyDashboard, className, canAdd,  ...props }) {
	const cn = className ? `EmptyBookGrid ${className}` : "EmptyBookGrid";

    const navigate = useNavigate()

    const handleOnAddBook = () => {
        navigate("./add/book")
    }

	return (
		<Element className={cn} {...props}>
            <EmptyBook asAddButton onClick={handleOnAddBook} />
		</Element>
	);
}

export default EmptyBookGrid;
