/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Utils
export const getAuthorsNames = (book) => {
    let authorsNames = []
    if (book) {
        // Authors names
        authorsNames = Object.hasOwn(book, "authors") ? book.authors : book.book_authors
    }
    return authorsNames
};

export const getTitleAndSubtitle = (book) => {
    let title = null
    let subtitle = null
    if (book) {
        const fullTitle = Object.hasOwn(book, "title") ? book.title : book.book_title
        if (fullTitle) {
            // Check for colon first
            let indexOfColon = fullTitle.indexOf(':')
            if (indexOfColon === -1) indexOfColon = fullTitle.indexOf(';')
            const endOfString = fullTitle.length
            const marker = indexOfColon !== -1 ? indexOfColon : endOfString
            title = fullTitle.substring(0, marker)
            if (marker !== endOfString) {
                subtitle = fullTitle.substring(marker + 1, endOfString)
            }
            // Next, check for parens if necessary
            if (!subtitle || subtitle.length === 0) {
                const regex = /\(([^\\)]+)\)/g
                const found = title.match(regex);
                if (found && found.length > 0) {
                    [subtitle] = found
                    title = fullTitle.replaceAll(subtitle, "")
                }
            }
        }
    }
    return { title, subtitle }
};

export const getDescription = (book) => {
    let description = null
    if (book && book.amazon) {
        const { amazon } = book
        description = book.description ?? amazon?.product_description ?? amazon.description
        if (description) description = description.trim()
    }
    return description
};

export const getUserBookInfo = (userBook) => {
    let title = null
    let image = null
    let subtitle = null
    let description = null
    let authorsNames = []
    if (userBook) {
        // Title & subtitle
        const { title: bookTitle, subtitle: bookSubtitle } = getTitleAndSubtitle(userBook)
        title = bookTitle
        subtitle = bookSubtitle
        // Authors names
        authorsNames = getAuthorsNames(userBook)
        // Description
        description = getDescription(userBook)
        // Image
        image = userBook.book_image
    }
    return { title, subtitle, authorsNames, description, image }
}

export const getBookInfo = (book) => {
    let title = null
    let image = null
    let subtitle = null
    let description = null
    let authorsNames = []
    let amazonLink = null
    if (book) {
        // Title & subtitle
        const { title: bookTitle, subtitle: bookSubtitle } = getTitleAndSubtitle(book)
        title = bookTitle
        subtitle = bookSubtitle
        // Authors names
        authorsNames = getAuthorsNames(book)
        // Description
        description = getDescription(book)
        // Image
        image = book.image
        // Amazon link
        amazonLink = book?.amazon?.product_url ?? null
    }
    return { title, subtitle, authorsNames, description, image, amazonLink }
};

export const slugifyBook = (title, authors) => {
    let stringToSlug = title
    if (authors) {
        const joinedAuthors = authors.join(";")
        stringToSlug += ` ${joinedAuthors}`
    }
	return stringToSlug
		.toLowerCase()
		.replace(/[^\w ]+/g, "")
		.replace(/ +/g, "-");
};


export const deSlugifyBook = (title) => {
    const parts = title.split("-")
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < parts.length; i++) {
        parts[i] = parts[i][0].toUpperCase() + parts[i].substr(1);
    }
    const result = parts.join(" ")
    return result
};
