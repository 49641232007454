/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import UserGridItem from "./UserGridItem";

import Row from "../Row";

import "./UserGrid.css";

// User Grid
function UserGrid({ className, users, avatarSize, showDisplayName, showFullName, ...props }) {
	const cn = className ? `UserGrid ${className}` : "UserGrid";
	return (
		<Row as="ul" className={cn} {...props}>
			{users &&
				users.map((user) => (
						<UserGridItem
							as="li"
							key={`ugi-${user?.displayName}`}
							user={user}
                            avatarSize={avatarSize}
                            showDisplayName={showDisplayName}
                            showFullName={showFullName}
						/>
					))}
		</Row>
	);
}

export default UserGrid;
