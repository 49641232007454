/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Import

import { toast } from 'react-hot-toast';

// Validators

const hasWhiteSpace = (s) => s.indexOf(' ') >= 0;

export const isValidEmail = (value) => String(value)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const isValidPassword = (value) => value && value.length >= 8 && !hasWhiteSpace(value);

export const isValidUsername = (value) => value && value.length >= 4 && value.length <= 30 && !hasWhiteSpace(value);

// Custom Form Config

export const DEFAULT_FORM_THEME_CONFIG = {
  errorNotificationFunc: () => toast.error('Please fix the errors before continuing.', {
    duration: 4000,
    position: 'top-center',
  }),
  customValidationFunction: (value, type) => {
    switch (type) {
      // Rule for username input:
      case 'username':
        if (isValidUsername(value)) {
          return false;
        }
        return 'Username must be between 4 and 30 characters long.';

        // Rule for email input:
      case 'email':
        if (isValidEmail(value)) {
          return false;
        }
        return 'Please enter a valid email.';

        // Rule for coordinates input:
      case 'password':
        if (isValidPassword(value)) {
          return false;
        }
        return 'Password must be at least 8 characters long.';

      default:
        return false;
    }
  },
};
