/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink } from "react-router-dom";

import MaxWidthContainer from "../../../components/MaxWidthContainer";
import MainMenuHamburgerButton from "./MainMenuHamburgerButton";
import Row from "../../../components/Row";

import "./MainHeader.css";

// Main Header

function MainHeader({ className, user }) {
    const cn = className ? `MainHeader ${className}` : "MainHeader";

    return (
        <Row className={cn} alignItems="center" justifyContent="center">
            <MaxWidthContainer>
                <Row
                    className="contents"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <NavLink to="/" className="app-name">
                        Readworthy
                    </NavLink>
                    <Row className="auth-buttons-mobile">
                        <MainMenuHamburgerButton size={20} />
                    </Row>
                    <Row className="auth-buttons-desktop">
                        <NavLink className="nav-link" to="/people">
                            People
                        </NavLink>
                        <NavLink className="nav-link" to={`/${user.displayName}`}>
                            Bookshelf
                        </NavLink>
                        <NavLink className="nav-link" to="/testing21/account">
                            Account
                        </NavLink>
                    </Row>
                </Row>
            </MaxWidthContainer>
        </Row>
    );
}

export default MainHeader;
