/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import MaxWidthContainer from '../../components/MaxWidthContainer';
import Column from '../../components/Column';
import UpgradeModal from './UpgradeModal';
import Page from '../../components/Page';

import './UpgradePage.css';

// Upgrade Page

function UpgradePage({ loggedInUser }) {

  return (
    <Page className="UpgradePage">
      <MaxWidthContainer>
        <Column>
          <UpgradeModal loggedInUser={loggedInUser} />
        </Column>
      </MaxWidthContainer>
    </Page>
  );
}

export default UpgradePage;
