const Color = {
  Blue: 'blue-color',
  Black: 'black-color',
  Red: 'red-color',
  Green: 'green-color',
  Grey1: 'grey1-color',
  Grey2: 'grey2-color',
  LightGrey1: 'light-grey1-color',
  LightGrey2: 'light-grey2-color',
  OffBlack: 'off-black-color',
  White: 'white-color',
};

export default Color;
