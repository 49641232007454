/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import Action from "./Action";
import Row from "../../Row";

import "./Actions.css";
import Icon from "../../Icon";

// Actions
function Actions({ kind, book, userBook, canAddHighlights, ...props }) {

	const theActions = useMemo(() => {
        let result = []
		switch (kind) {
			case "book": {
                if (!book) return []
                result = []
                const highlightsCount = userBook?.counts?.highlights
                if (userBook && highlightsCount) {
                    result.push({
                        title: `${highlightsCount} Highlights`, 
                        key: "view-highlights",
                        link: `./highlights`,
                        type: "link"
                    })
                } else if (canAddHighlights) {
                    result.push({
                        title: `Add highlights`, 
                        key: "add-highlights",
                        link: `./highlights`,
                        type: "link-button",
                        icon: Icon.Type.Highlighter,
                    })
                }
				break;
			}
			case "highlights": {
				break;
			}
            default:
                break
		}
        return result
	}, [kind, book, userBook, canAddHighlights]);

	return (
        <Row className="Actions" {...props}>
            {theActions?.map((act) => <Action key={act.key} action={act} />)}
        </Row>    
    );
}

export default Actions;
