/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { where } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import ScrollSnapChild from "../ScrollSnapChild";

import AnonymousFooter from "../../../layouts/AnonymousLayout/AnonymousFooter";

import UserGrid from "../../../components/UserGrid";
import BookGrid from "../../../components/BookGrid";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Color from "../../../components/Color";

import useBooks from "../../../hooks/useBooks";
import useUsers from "../../../hooks/useUsers";

import "./DiscoverSection.css";

// Discover Section
function DiscoverSection({ className, ...props }) {
    const cn = className ? `DiscoverSection ${className}` : "DiscoverSection";
    const navigate = useNavigate()

    const [books] = useBooks(20);
    const [users] = useUsers(10, where("source", "==", "crawl"));

    // Handlers
    const handleOnClick = () => {
        navigate("./people")
    }

    return (
        <ScrollSnapChild className={cn} {...props}>
            <Column className="content" alignItems="center">
                <Label
                    className="heading"
                    type={Label.Type.Heading1}
                    color={Color.Black}
                >
                    Here&apos;s some of our most popular experts
                </Label>
                <UserGrid users={users} avatarSize={160} showFullName />
                <button
                    onClick={() => handleOnClick("people")}
                    type="button"
                    className="view-all experts"
                >
                    View all experts
                </button>
                <Label
                    className="heading"
                    type={Label.Type.Heading1}
                    color={Color.Black}
                >
                    And our most recommended books
                </Label>
                <BookGrid noOwner books={books} />
                <AnonymousFooter />
            </Column>
        </ScrollSnapChild>
    );
}

export default DiscoverSection;
