/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useLocation, useNavigate } from "react-router-dom";

import ReactModal from "react-modal";

import { useEffect, useState } from "react";

import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../clients/firebase";

import useStripeProducts from "../../../hooks/useStripeProducts";
import IconButton from "../../../components/buttons/IconButton";
import { handleError } from "../../../utils/errors";
import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import ProductLoading from "./ProductLoading";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import Product from "./Product";

import "./UpgradeModal.css";

// Upgrade Modal
function UpgradeModal({ loggedInUser }) {
	// Nav
	const navigate = useNavigate();
    const location = useLocation();

	// UX state
	const [loading, setLoading] = useState(false);

	// Stripe handling
	const [stripeProducts] = useStripeProducts();
    const [checkoutSessionRef, setCheckoutSessionRef] = useState(null)

    useEffect(() => {
        if (checkoutSessionRef) {
            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(checkoutSessionRef, (snap) => {
                const { error, url } = snap.data();
                if (error) {
                    // Show an error to your customer.
                    handleError()
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                    setLoading(false);
                }
            });
        }
    }, [checkoutSessionRef]);

	// Handlers
	const handleOnCheckout = async (priceId) => {
		setLoading(true);
        const sessionRef = await addDoc(
            collection(db, "stripe_customers", loggedInUser.uid, "checkout_sessions"),
            {
               price: priceId,
               success_url: window.location.href,
               cancel_url: window.location.href,
               mode: "payment"
            }
         );
		if (!sessionRef) {
            setLoading(false);
            handleError()
            return
        }
        // If everything went smoothly, set the session ref
        setCheckoutSessionRef(sessionRef)
	};

	const handleOnClose = () => {
        let forwardingPathname = location.pathname
        forwardingPathname = forwardingPathname.replace("upgrade", "")
		navigate(forwardingPathname);
	};

	return (
		<ReactModal
			isOpen
			className="Modal UpgradeModal"
            onRequestClose={handleOnClose}
			overlayClassName="ModalOverlay UpgradeModalOverlay"
		>
			<Column className="content">
				<Column className="header" justifyContent="center">
					<Row>
						<Label className="app-name" type={Label.Type.Heading3}>
							Upgrade account
						</Label>
					</Row>
					<Label
						center
						className="subtitle"
						type={Label.Type.Body}
						mT={1}
					>
						Unlock all features to get the most from your
						membership.
					</Label>
					<IconButton
						className="close"
						leftIconType={Icon.Type.Close}
						iconSize={28}
						onClick={handleOnClose}
					/>
				</Column>
				<Element className="body">
					{(stripeProducts && stripeProducts.length > 0) &&
						stripeProducts.map((prod) => (
							<Product
								key={prod.doc_id}
								product={prod}
								loading={loading}
								onCheckout={handleOnCheckout}
							/>
						))}
                    {(!stripeProducts || stripeProducts.length === 0) && (
                        <ProductLoading />
                    )}
				</Element>
			</Column>
		</ReactModal>
	);
}

export default UpgradeModal;
