/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useEffect } from "react";

import Page from "../../components/Page";

import usePaginateCollection from "../../hooks/usePaginateCollection";

import DiscoverSection from "./DiscoverSection";
import ScrollSnapParent from "./ScrollSnapParent";
import HeroSection from "./HeroSection";

import "./LandingPage.css";

// Landing Page
function LandingPage() {

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

    const { results: books } = usePaginateCollection(
        "books",
        null,
        "title",
        "desc",
        21
    );

    return (
        <Page className="LandingPage">
            <ScrollSnapParent>
                <HeroSection books={books} />
                <DiscoverSection />
            </ScrollSnapParent>
        </Page>
    );
}

export default LandingPage;
