/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import ReactModal from "react-modal";

import ShareContent from "../ShareContent";

import IconButton from "../buttons/IconButton";
import Element from "../Element";
import Column from "../Column";
import Label from "../Label";
import Icon from "../Icon";
import Row from "../Row";

import "./ShareModal.css";

// Share Modal
function ShareModal({
    book,
    bookOwner,
    loading,
    onClose,
    className,
    loggedInUser,
    ...props
}) {
    const cn = className ? `Modal ShareModal ${className}` : `Modal ShareModal`;

    // Handlers
    const handleOnClose = () => {
        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <ReactModal
            isOpen
            className={`${cn}`}
            onRequestClose={handleOnClose}
            overlayClassName="ModalOverlay ShareModalOverlay"
            {...props}
        >
            <Column>
                <Row className="header">
                    <div className="spacer" />
                    <Label bold type={Label.Type.Heading4}>
                        Share
                    </Label>
                    <Element
                        className="btn-close-container"
                        style={{ minWidth: "28px", minHeight: "28px" }}
                    >
                        <IconButton
                            className="close"
                            leftIconType={Icon.Type.Close}
                            disabled={loading}
                            iconSize={28}
                            onClick={handleOnClose}
                        />
                    </Element>
                </Row>
                <Element className="body">
                    <ShareContent
                        book={book}
                        bookOwner={bookOwner}
                        onRequestClose={handleOnClose}
                    />
                </Element>
                <Row className="footer" />
            </Column>
        </ReactModal>
    );
}

export default ShareModal;
