/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from "../../Element";
import Loading from "../../Loading";
import Label from "../../Label";
import Icon from "../../Icon";

import "./IconButton.css";

// Icon Button

function IconButton({
	children,
	className,
	disabled,
	leftIconType,
	leftIconColor,
	rightIconType,
	rightIconColor,
	backgroundColor,
	iconSize,
	loading,
	minimal,
	title,
	onClick,
	...props
}) {
	// Classes
	let cn = className ? `Button IconButton ${className}` : "Button IconButton";
	if (minimal) cn += " minimal";

	// Override color
	if (backgroundColor) {
		cn += ` bg-${backgroundColor}`;
	}

	// Add styles
	const styles = {};

	return (
		<Element
			{...props}
			as="button"
			disabled={disabled}
			className={cn}
			style={styles}
			onClick={onClick}
		>
			{leftIconType && !loading && (
                <Element style={{ minWidth: `${iconSize ?? 17}px`}}>
                    <Icon
                        type={leftIconType}
                        color={leftIconColor}
                        mR={title && !loading ? 2 : 0}
                        size={iconSize ?? 17}
                    />
                </Element>
			)}
			{title && !loading && (
				<Label type={Label.Type.Small} nonBold>
					{title}
				</Label>
			)}
			{loading && (
				<Label type={Label.Type.InnerButton} nonBold pT={1} pB={1}>
					<Loading style={{ color: "white" }} size={14} />
				</Label>
			)}
			{rightIconType && !loading && (
                <Element style={{ minWidth: `${iconSize ?? 17}px`}}>
                    <Icon
                        type={rightIconType}
                        color={rightIconColor}
                        mL={title && !loading ? 2 : 0}
                        size={iconSize ?? 17}
                    />
                </Element>
			)}
		</Element>
	);
}

export default IconButton;
