/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports
import getBrowserFingerprint from 'get-browser-fingerprint';
import localforage from 'localforage';

import { BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST } from './constants';

// Get's the stored sort order from local storage
export const getSortOrder = async (userId) => {
    let order = BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST
    // If we have a userId, use it to get the preferred sort order
    // otherwise, calculate the browser fingerprint
    const identifier = userId ?? getBrowserFingerprint()
    try {
        const value = await localforage.getItem(`${identifier}-dashboard-book-sort-order`);
        if (value) order = value
    } catch (err) {
        console.log('Error getting sort order');
    }
    return order
};

export const setSortOrder = async (value, userId) => {
    let success = false
    // If we have a userId, use it to set the preferred sort order
    // otherwise, calculate the browser fingerprint
    const identifier = userId ?? getBrowserFingerprint()
    const err = await localforage.setItem(`${identifier}-dashboard-book-sort-order`, value)
    if (!err) success = true
    return success
};
  
  