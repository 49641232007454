/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { toast } from "react-hot-toast";

// Errors

export const USER_DOES_NOT_EXIST = "Local: Error (auth/user-not-found).";

export const FIREBASE_USER_NOT_FOUND = "Firebase: Error (auth/user-not-found).";
export const FIREBASE_INVALID_EMAIL = "Firebase: Error (auth/invalid-email).";
export const FIREBASE_MISSING_EMAIL = "Firebase: Error (auth/missing-email).";
export const FIREBASE_INVALID_CREDENTIAL =
	"Firebase: Error (auth/invalid-credential).";
export const FIREBASE_MISSING_PASSWORD =
	"Firebase: Error (auth/missing-password).";
export const FIREBASE_ERROR_EMAIL_ALREADY_IN_USE =
	"Firebase: Error (auth/email-already-in-use).";

export const ADD_PRODUCT_URL_FAIL = "add_product_url_fail";

export const LOCAL_INVALID_EMAIL = "Local: Error (auth/email-not-provided)";
export const LOCAL_INVALID_PASSWORD =
	"Local: Error (auth/password-not-provided)";
export const LOCAL_INVALID_FIRST_NAME =
	"Local: Error (auth/invalid-first-name)";
export const LOCAL_INVALID_LAST_NAME = "Local: Error (auth/invalid-last-name)";

// Error handlers

export const handleError = (err) => {
	let msg = "Something went wrong. Please try again.";
	switch (err.message) {
		case FIREBASE_USER_NOT_FOUND:
		case FIREBASE_INVALID_EMAIL:
		case FIREBASE_MISSING_PASSWORD:
		case FIREBASE_INVALID_CREDENTIAL:
		case FIREBASE_MISSING_EMAIL: {
			msg = "Invalid credentials. Please try again.";
			break;
		}
		case ADD_PRODUCT_URL_FAIL: {
			msg = "Add product failed. Please try again.";
			break;
		}
		case LOCAL_INVALID_EMAIL: {
			msg = "Please enter a valid email.";
			break;
		}
		case LOCAL_INVALID_PASSWORD: {
			msg = "Please enter a valid password.";
			break;
		}
		case LOCAL_INVALID_FIRST_NAME: {
			msg = "Please enter a valid first name.";
			break;
		}
		case LOCAL_INVALID_LAST_NAME: {
			msg = "Please enter a valid last name.";
			break;
		}
		default:
			break;
	}
	toast.error(msg);
};
