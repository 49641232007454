export const PEOPLE = [
  'georgeorwell',
  'jkrowling',
  'stephenking',
  'franzkafka',
  'leotolstoy',
  'lewiscarroll',
  'janeausten',
  'virginiawoolf',
  'hemingway',
  'cslewis',
  'shakespeare',
  'tseliot',
  'marktwain',
  'emilydickinson',
];
