/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink } from 'react-router-dom';

import Element from '../../../Element';
import Label from '../../../Label';

import './SignUpFooter.css';

// Footer

function SignUpFooter({ className }) {
  const cn = className ? `SignUpFooter ${className}` : 'SignUpFooter';

  return (
    <Element className={cn}>
      <NavLink to="/">
        <Label className="app-name">Readworthy</Label>
      </NavLink>
    </Element>
  );
}

export default SignUpFooter;
