/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import IconButton from '../../../buttons/IconButton';
import Label from '../../../Label';
import Icon from '../../../Icon';
import Row from '../../../Row';

import './Navigation.css';

// Navigation

function Navigation({
  className, title, showBackButton, onBack, ...props
}) {
  const cn = className ? `Navigation ${className}` : 'Navigation';

  const handleOnBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    }
  };

  return (
    <Row
      alignItems="center"
      justifyContent="flex-start"
      {...props}
      className={cn}
    >
      <IconButton
        iconSize={30}
        className={showBackButton ? 'btn-back' : 'btn-back hidden'}
        onClick={handleOnBack}
        leftIconType={Icon.Type.ArrowLeftThick}
      />
      <Label type={Label.Type.Heading4} semibold className="title">
        {title}
      </Label>
    </Row>
  );
}

export default Navigation;
