/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Permissions

// export const canAddBook = () => false

export const canAddBook = (userIsPaid, books) => {
	let canAdd = userIsPaid;
	if (!canAdd) {
		canAdd = books && books.length < 10;
	}
	return canAdd;
};
