/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useState } from "react";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { useNavigate } from "react-router-dom";

import { deleteUserBookById } from "../../../../clients/firebase";

import DeleteModal from "../../../../components/DeleteModal";
import Element from "../../../../components/Element";
import Label from "../../../../components/Label";
import Color from "../../../../components/Color";
import Icon from "../../../../components/Icon";
import Row from "../../../../components/Row";

import { toastIt } from "../../../../utils/alerts";

import "./OptionsMenu.css";

// Options Menu
function OptionsMenu({ onChange, className, userBook, bookOwner, ...props }) {
	const navigate = useNavigate();

	const cn = className ? `OptionsMenu ${className}` : `OptionsMenu`;

	// UI state
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	// Helpers
	const toggleDrawer = () => {
		setIsOpenDrawer(!isOpenDrawer);
	};

	const toggleDeleteModal = () => {
		setIsOpenDeleteModal(!isOpenDeleteModal);
	};

	// Handlers
	const handleOnClickMenuItem = () => {
		// Open the delete modal
		toggleDeleteModal(true);
		// Close the drawer
		toggleDrawer();
	};

	const handleOnDelete = async () => {
		setIsDeleting(true);
		const res = await deleteUserBookById({ user_book_id: userBook.id });
		setIsDeleting(false);
		setIsOpenDeleteModal(false);
		if (res?.data?.success) {
			toastIt("Successfully removed!", "success");
			navigate(`/${bookOwner.displayName}`);
		} else {
			toastIt("Something went wrong. Try again.", "error");
		}
	};

	return (
		<>
			<Element className={`${cn} desktop`} {...props}>
				<Menu
					align="center"
                    direction="top"
					gap={12}
					menuButton={
						<MenuButton>
							<Label className="btn-options" color={Color.Black}>Options</Label>
						</MenuButton>
					}
					{...props}
				>
					<MenuItem
						className="option"
						key="delete"
						value="delete"
						onClick={() => handleOnClickMenuItem("delete")}
					>
						<Row justifyContent="space-between" width="100%">
							<Label
								className="delete"
								type={Label.Type.SmallBody}
							>
								Remove from library
							</Label>
							<Element className="option-icon-container">
								<Icon
									className="option-icon delete"
									type={Icon.Type.Delete}
									color={Color.Red}
									size={20}
								/>
							</Element>
						</Row>
					</MenuItem>
				</Menu>
			</Element>
			<Element className={`${cn} mobile`} {...props}>
                <Label className="btn-options" onClick={toggleDrawer} color={Color.Black}>Options</Label>
				<Drawer
					open={isOpenDrawer}
					onClose={toggleDrawer}
					direction="bottom"
					className="drawer"
					duration={150}
					size="66px"
				>
					<Element
						className="option"
						key="delete"
						value="Delete"
						onClick={() => handleOnClickMenuItem("delete")}
					>
						<Row justifyContent="space-between">
							<Label
								bold
								className="delete"
								type={Label.Type.SmallBody}
							>
								Remove from library
							</Label>
							<Element className="option-icon-container">
								<Icon
									className="option-icon delete"
									type={Icon.Type.Delete}
									color={Color.Red}
									size={20}
								/>
							</Element>
						</Row>
					</Element>
				</Drawer>
			</Element>
			{isOpenDeleteModal && (
				<DeleteModal
					loading={isDeleting}
					onClose={toggleDeleteModal}
					onDelete={handleOnDelete}
					modalTitle="Remove"
					cancelButtonTitle="Nevermind"
					deleteButtonTitle="Yes, remove"
					textTitle="Are you sure?"
					textSubtitle="You are about to remove this book from your library. All of the notes and highlights that you've created will be deleted as well."
				/>
			)}
		</>
	);
}

export default OptionsMenu;
