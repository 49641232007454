/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import EditNameForm from "../../../components/forms/EditNameForm";
import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";

import "./ProfileSettingsSection.css";

// Profile Settings Section
function ProfileSettingsSection({
	className,
	loggedInUser,
	loggedInUserProfile,
	loggedInUserProfileLoading,
	...props
}) {
	const cn = className
		? `ProfileSettingsSection ${className}`
		: "ProfileSettingsSection";

	return (
		<Row className={cn} {...props}>
			<Column className="headers">
				<Label
					type={Label.Type.Heading3}
					bold
					className="section-title"
				>
					Name
				</Label>
				<Label type={Label.Type.Body} className="section-subtitle">
					Changes will be reflected on your dashboard.
				</Label>
			</Column>
			<Element className="form-container">
				<EditNameForm
					buttonTitle="Update name"
					dark
					loggedInUser={loggedInUser}
                    loggedInUserProfile={loggedInUserProfile}
				/>
			</Element>
		</Row>
	);
}

export default ProfileSettingsSection;
