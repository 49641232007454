/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import { Link } from "react-router-dom";

import Element from "../../Element";
import Label from "../../Label";

import "./Crumb.css";

// Crumb
function Crumb({ crumb, className, ...props }) {
	let cn = className ? `Crumb ${className}` : "Crumb";
    if (crumb.type) {
        cn += ` ${crumb.type}`
    }



    const { crumbTitle, crumbLink } = useMemo(() => {
        let title = ""
        let link = ""
        if (crumb?.title === "all") {
            title = "Go to search"
            link = "/people"
        } else {
            title = crumb?.title
            link = crumb?.link
        }
        return { crumbTitle: title, crumbLink: link }
    }, [crumb])

	return (
		<Element className={cn}>
			{["displayName", "bookTitle"].includes(crumb.type) && (
				<Link to={crumbLink} className="link" {...props}>
					{crumbTitle}
				</Link>
			)}
            {crumb.type === "divider" && (
				<Label className="divider" mL={2} mR={2}>{crumb.title}</Label>
			)}
		</Element>
	);
}

export default Crumb;
