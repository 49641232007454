/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink as RRDNavLink } from "react-router-dom";

import Element from "../../../components/Element";
import Label from "../../../components/Label";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";

import "./NavLink.css";

// Nav Page

function NavLink({
	to,
	text,
	iconType,
	activeIconType,
	inactiveIconType,
	iconColor,
	theme,
	className,
	...props
}) {
	let cn = className ? `NavLink ${className}` : `NavLink`;
	const themeAddOn = theme ?? "light";
	cn += ` ${themeAddOn}`;

	return (
		<Element className={cn} {...props}>
			<RRDNavLink to={to}>
				{({ isActive }) => (
					<Row className={isActive ? "active" : "inactive"}>
						{activeIconType && isActive && <Icon type={activeIconType} color={iconColor} mR={3} size={28} />}
						{inactiveIconType && !isActive && <Icon type={inactiveIconType} color={iconColor} mR={3} size={28} />}
						<Label>
							{text}
						</Label>
					</Row>
				)}
			</RRDNavLink>
		</Element>
	);
}

export default NavLink;
