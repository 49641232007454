/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useState } from "react";

import { logInWithEmailAndPassword } from "../../../clients/firebase";

import EmailLoginForm from "./EmailLoginForm";
import LogInFooter from "./LogInFooter";
import LogInHeader from "./LogInHeader";
import Element from "../../Element";
import Column from "../../Column";

import "../Forms.css";
import "./LogInForm.css";

// Log In Form

function LogInForm({ className, includeHeader, includeFooter, ...props }) {
	const cn = className
		? `DubitoForm LogInForm ${className}`
		: "DubitoForm LogInForm";

	// UI state
	const [loading, setLoading] = useState(false);

	// New user state
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);

	const handleOnLogIn = async () => {
        setLoading(true)
        await logInWithEmailAndPassword(
            email,
            password,
            true
        )
        // No need to do anything bc the login page
        // will automatically redirect to the user's 
        // dashboard
		setLoading(false)
	};

	return (
		<Column {...props} className={cn}>
			<Element style={{ width: "100%" }}>
				{includeHeader && <LogInHeader title="Log in" />}
				<EmailLoginForm
					onChange={(newEmail, newPassword) => {
						setEmail(newEmail);
						setPassword(newPassword);
					}}
					onContinue={handleOnLogIn}
                    buttonTitle="Continue"
                    loading={loading}
				/>
			</Element>
			{includeFooter && <LogInFooter />}
		</Column>
	);
}

export default LogInForm;
