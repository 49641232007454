/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import { deSlugifyBook, slugifyBook } from "../../utils/books";

import Crumb from "./Crumb";
import Row from "../Row";

import "./Crumbs.css";

// Crumbs
function Crumbs({
	kind,
	book,
	bookOwner,
	userBook,
	usernameParam,
	bookSlugParam,
	...props
}) {

	const daCrumbs = useMemo(() => {
		let result = [];
		switch (kind) {
			case "book": {
				result = [
					{
						title: bookOwner ? `${bookOwner.displayName}` : usernameParam,
						key: bookOwner ? bookOwner.displayName : usernameParam,
						link: bookOwner ? `/${bookOwner.displayName}/books` : `/${usernameParam}/books`,
						type: "displayName",
					},
				];
				break;
			}
			case "reader":
			case "highlights": {
				result = [
					{
						title: bookOwner ? `${bookOwner.displayName}` : usernameParam,
						key: bookOwner ? bookOwner.displayName : usernameParam,
						link: bookOwner ? `/${bookOwner.displayName}/books` : `/${usernameParam}/books`,
						type: "displayName",
					},
					{
						title: `/`,
						key: "second-divider",
						type: "divider",
					},
					{
						title: book ? book.title : deSlugifyBook(bookSlugParam),
						key: book ? slugifyBook(book.title, book.authors) : bookSlugParam,
						link: bookOwner ? `/${bookOwner.displayName}/books/${book.slug}` : `/${usernameParam}/books/${bookSlugParam}`,
						type: "bookTitle",
					},
				];
				break;
			}
			default:
				break;
		}
		return result;
	}, [kind, book, bookOwner, usernameParam, bookSlugParam]);

	return (
		<Row className="Crumbs" {...props}>
			{daCrumbs?.map((crumb) => (
				<Crumb key={crumb.key} crumb={crumb} />
			))}
		</Row>
	);
}

export default Crumbs;
