/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import IconButton from "../../../components/buttons/IconButton";
import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";

import { toastIt } from "../../../utils/alerts";

import "./Highlight.css";

// Highlight
function Highlight({ highlight, className, ...props }) {
	const cn = className ? `Highlight ${className}` : `Highlight`;

	const handleOnCopy = () => {
        const textToCopy = highlight.body
		navigator.clipboard.writeText(textToCopy)
        toastIt("Highlight copied", "success")
	};

	return (
		<Column {...props} className={cn} onClick={handleOnCopy}>
			<Element as="blockquote">
				<Label as="mark">{highlight.body}</Label>
				<Row mT={2}>
					<Label className="location mobile">
						{highlight.location}
					</Label>
					<Label className="location desktop">
						Position {highlight.location}
					</Label>
					<Label className="location mobile" mL={2} mR={2}>
						/
					</Label>
						<IconButton
							iconSize={12}
							className="btn-copy-and-paste mobile"
							title="Copy text"
							rightIconType={Icon.Type.Copy}
						/>
				</Row>
			</Element>
			<IconButton
				iconSize={12}
				className="btn-copy-and-paste desktop"
				title="Click to copy text"
				rightIconType={Icon.Type.Copy}
			/>
		</Column>
	);
}

export default Highlight;
