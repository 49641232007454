/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { NavLink, useLocation } from "react-router-dom";

import { useMemo } from "react";

import MaxWidthContainer from "../../../components/MaxWidthContainer";
import Element from "../../../components/Element";
import Row from "../../../components/Row";

import "./AnonymousHeader.css";

// Anonymous Header

function AnonymousHeader({ className }) {
    const location = useLocation();
    const isLanding = useMemo(
        () => location.pathname === "/",
        [location?.pathname]
    );
    const isLogin = useMemo(
        () => location.pathname.includes("login"),
        [location?.pathname]
    );
    const isSignUp = useMemo(
        () => location.pathname.includes("signup"),
        [location?.pathname]
    );

    // Determine the className
    const cn = useMemo(() => {
        let result = className
            ? `AnonymousHeader ${className}`
            : "AnonymousHeader";
        if (isLanding || isLogin || isSignUp) {
            result += " light landing";
        }
        if (isLogin || isSignUp) {
            result += ` isAuth`;
        }
        if (!isLogin && !isSignUp) {
            result += ` no-bottom-border`;
        }
        return result;
    }, [className, isLanding, isLogin, isSignUp]);

    const { loginBtnCn, signUpBtnCn, peopleBtnCn } = useMemo(() => {
        const loginCn = isLanding ? `login landing` : "login";
        const signUpCn = isLanding ? `signup landing` : "signup";
        const peopleCn = isLanding ? `people landing` : "people";
        return {
            loginBtnCn: loginCn,
            signUpBtnCn: signUpCn,
            peopleBtnCn: peopleCn,
        };
    }, [isLanding]);

    return (
        <Row className={cn} alignItems="center" justifyContent="center">
            <MaxWidthContainer>
                <Row
                    className="contents"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <NavLink to="/" className="app-name">
                        Readworthy
                    </NavLink>
                    <Element className="auth-buttons">
                        <NavLink to="people" className={peopleBtnCn}>
                            People
                        </NavLink>
                        <NavLink to="login" className={loginBtnCn}>
                            Log in
                        </NavLink>
                        <NavLink to="signup/username" className={signUpBtnCn} />
                    </Element>
                </Row>
            </MaxWidthContainer>
        </Row>
    );
}

export default AnonymousHeader;
