/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { Routes, Route } from 'react-router-dom';
import { FormThemeProvider } from 'react-form-component';
import { useAuthState } from 'react-firebase-hooks/auth';

import HighlightsPage from './modules/HighlightsPage';
import ProtectedRoute from './modules/ProtectedRoute';
import DashboardPage from './modules/DashboardPage';
import SignUpPage from './modules/auth/SignUpPage';
import AccountPage from './modules/AccountPage';
import LoginPage from './modules/auth/LoginPage';
import LandingPage from './modules/LandingPage';
import AddBookPage from './modules/AddBookPage';
import UpgradePage from './modules/UpgradePage';
import PeoplePage from './modules/PeoplePage';
import BookPage from './modules/BookPage';
import NavPage from './modules/NavPage';
import Reader from './modules/Reader';

import AnonymousLayout from './layouts/AnonymousLayout';
import MainLayout from './layouts/MainLayout';

import { DEFAULT_FORM_THEME_CONFIG } from './components/forms/utils';
import FullPageLoader from './components/FullPageLoader';
import CustomToaster from './components/CustomToaster';
import BuiltBy from './components/BuiltBy';

import useUserIsPaid from './hooks/useUserIsPaid';

import { auth } from './clients/firebase';

// App

function App() {
  // Get info about the logged in user
  const [loggedInUser, loggedInUserLoading] = useAuthState(auth);
  const [loggedInUserIsPaid] = useUserIsPaid(loggedInUser?.uid)

  // Are we still loading?
  if (loggedInUserLoading) {
    return <FullPageLoader />;
  }

  return (
    <FormThemeProvider theme={DEFAULT_FORM_THEME_CONFIG}>
        <div><CustomToaster /></div>
        <div className="App">
            <Routes>
                <Route path="/" element={loggedInUser ? <MainLayout user={loggedInUser} /> : <AnonymousLayout />}>
                    <Route index element={<LandingPage />} />
                    <Route path="login" element={<LoginPage loggedInUser={loggedInUser} userLoading={loggedInUserLoading} />} />
                    <Route path="people" element={<PeoplePage />} />
                    <Route path="signup/:step" element={<SignUpPage loggedInUser={loggedInUser} userLoading={loggedInUserLoading} />} />
                    <Route element={<ProtectedRoute isAuthorized={!loggedInUser?.isAnonymous} />}>
                        <Route path="nav" element={<NavPage loggedInUser={loggedInUser} loggedInUserIsPaid={loggedInUserIsPaid} />} />
                        <Route path=":username/account*" element={<AccountPage loggedInUser={loggedInUser} loggedInUserIsPaid={loggedInUserIsPaid} />} />
                    </Route>
                    <Route path=":username*" element={<DashboardPage loggedInUser={loggedInUser} userLoading={loggedInUserLoading} loggedInUserIsPaid={loggedInUserIsPaid} />} />
                    <Route path=":username/books/:book_slug/description" element={<Reader kind="description" loggedInUser={loggedInUser} loggedInUserIsPaid={loggedInUserIsPaid} />} /> 
                    <Route path=":username/books/:book_slug/highlights" element={<HighlightsPage loggedInUser={loggedInUser} loggedInUserIsPaid={loggedInUserIsPaid} />} /> 
                    <Route path=":username/books/:book_slug" element={<BookPage loggedInUser={loggedInUser} loggedInUserIsPaid={loggedInUserIsPaid} />} /> 
                </Route>
            </Routes>
            <Routes>
                <Route path=":username/add/book" element={<AddBookPage />} />
            </Routes>
            <Routes>
                <Route path=":segment?/upgrade" element={<UpgradePage loggedInUser={loggedInUser} />} />
                <Route path=":segment1?/:segment2?/upgrade" element={<UpgradePage loggedInUser={loggedInUser} />} />
                <Route path=":segment1?/:segment2?/:segment3?/upgrade" element={<UpgradePage loggedInUser={loggedInUser} />} />
            </Routes>
        </div>
        <BuiltBy />
    </FormThemeProvider>
  );
}

export default App;
