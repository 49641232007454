/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import { FIREBASE_ERROR_EMAIL_ALREADY_IN_USE } from "../../../utils/errors";
import { registerWithEmailAndPassword } from "../../../clients/firebase";
import UpdateUsernameForm from "./UpdateUsernameForm";
import CreateUserForm from "./CreateUserForm";
import SignUpFooter from "./SignUpFooter";
import SignUpHeader from "./SignUpHeader";
import Navigation from "./Navigation";
import Element from "../../Element";
import Column from "../../Column";

import "../Forms.css";
import "./SignUpForm.css";

// Steps
const EMAIL_PASSWORD_STEP = "email_password_step";
const USERNAME_STEP = "username_step";

// Sign Up Form
function SignUpForm({ className, includeHeader, includeFooter, ...props }) {
	const cn = className
		? `DubitoForm SignUpForm ${className}`
		: "DubitoForm SignUpForm";

	// URLs & nav
	const { step: paramsStep } = useParams();
	const navigate = useNavigate();
	const { state } = useLocation();

	// UI state
	const [step, setStep] = useState(null);
	const [loading, setLoading] = useState(false);

	// Change the URL based on the param's step
	useEffect(() => {
		if (paramsStep === "email" && step !== EMAIL_PASSWORD_STEP) {
			setStep(EMAIL_PASSWORD_STEP);
		} else if (paramsStep === "username" && step !== USERNAME_STEP) {
			setStep(USERNAME_STEP);
		}
	}, [paramsStep, step, setStep]);

	// New user state
	const [email, setEmail] = useState(state?.email ?? null);
	const [password, setPassword] = useState(state?.password ?? null);
	const [username, setUsername] = useState(state?.username ?? null);

	const { showBackButton, headerTitle } = useMemo(() => {
		const show = step === EMAIL_PASSWORD_STEP;
		let title = null;
		if (step === EMAIL_PASSWORD_STEP) {
			title = "Now, create an account";
		} else if (step === USERNAME_STEP) {
			title = "First, pick a username";
		}
		return { showBackButton: show, headerTitle: title };
	}, [step]);

	const handleOnBack = () => {
		if (step === EMAIL_PASSWORD_STEP) {
			navigate("/signup/username", {
				state: { username, email, password },
			});
		}
	};

	const handleOnForward = () => {
		if (step === USERNAME_STEP) {
			navigate("/signup/email", { state: { username, email, password } });
		} else if (step === EMAIL_PASSWORD_STEP) {
			navigate("/signup/username", {
				state: { username, email, password },
			});
		}
	};

	const handleOnSignUp = async () => {
		setLoading(true);
		const res = await registerWithEmailAndPassword(
			username,
			email,
			password,
			false
		);
        setLoading(false);
		if (res?.message === FIREBASE_ERROR_EMAIL_ALREADY_IN_USE) {
			// There was an error
			toast.error("The email provided is already in use.", {
				duration: 4000,
				position: "top-center",
			});
		} else {
			// No error, redirect to the user's dashboard.
			navigate(`/${username}`);

		}
	};

	return (
		<Column {...props} className={cn}>
			<Element style={{ width: "100%" }}>
				{includeHeader && <SignUpHeader title="Join" />}
				<Navigation
					title={headerTitle}
					showBackButton={showBackButton}
					onBack={() => handleOnBack()}
				/>
				{step === USERNAME_STEP && (
					<UpdateUsernameForm
						onChange={(newUsername) => setUsername(newUsername)}
						onContinue={handleOnForward}
					/>
				)}
				{step === EMAIL_PASSWORD_STEP && (
					<CreateUserForm
						onChange={(newEmail, newPassword) => {
							setEmail(newEmail);
							setPassword(newPassword);
						}}
						onContinue={handleOnSignUp}
						buttonTitle="Submit"
						loading={loading}
					/>
				)}
			</Element>
			{includeFooter && <SignUpFooter />}
		</Column>
	);
}

export default SignUpForm;
