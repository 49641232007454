/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import './MaxWidthContainer.css';

// Max Width Container

function MaxWidthContainer({ maxWidth, className, children }) {
  const cn = className ? `MaxWidthContainer ${className}` : 'MaxWidthContainer';
  const styles = maxWidth ? { maxWidth } : {};

  return (
    <div className={cn} style={styles}>
      {children}
    </div>
  );
}

MaxWidthContainer.defaultProps = {
  maxWidth: null,
};

export default MaxWidthContainer;
