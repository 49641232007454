/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Form, { Input, SubmitButton } from 'react-form-component';
import { NavLink } from 'react-router-dom';

import { useEffect, useState, useMemo } from 'react';
import Loading from '../../../Loading';
import Element from '../../../Element';
import Label from '../../../Label';

import '../../Forms.css';
import './EmailLogInForm.css';

// Email Login Form

function EmailLoginForm({
  className,
  onChange,
  onContinue,
  loading,
  buttonTitle = 'Continue',
  ...props
}) {
  const cn = className
    ? `DEFormContainer EmailLogInForm ${className}`
    : 'DEFormContainer EmailLogInForm';

  // User info
  const [password, setPassword] = useState(null);
  const [email, setEmail] = useState(null);

  const { submitButtonDisabled } = useMemo(() => {
    const submitButton = false;
    return { submitButtonDisabled: submitButton };
  }, [email, password]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(email, password);
    }
  }, [email, password]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Element {...props} className={cn}>
      <Form fields={['email', 'password']} onSubmit={handleOnSubmit}>
        <Input
          name="email"
          type="email"
          label="Email"
          className={
						email && email.length > 0
						  ? 'FloatingInput EmailInput filled'
						  : 'FloatingInput EmailInput'
					}
          onChange={(val) => setEmail(val)}
        />
        <Input
          name="password"
          type="password-novalidation"
          label="Password"
          className={
						password && password.length > 0
						  ? 'FloatingInput filled'
						  : 'FloatingInput'
					}
          onChange={(val) => setPassword(val)}
        />
        <SubmitButton
          disabled={submitButtonDisabled}
          className="continue"
          onClick={() => {
					  if (typeof onContinue === 'function') {
					    onContinue(email, password);
            }
          }}
        >
          {loading && <Loading />}
          {!loading && <Label>{buttonTitle}</Label>}
        </SubmitButton>
        <Label className="already-link">
          Don&apos;t have an account?
          {' '}
          <NavLink className="text-link" to="/signup/username">
            Join
          </NavLink>
        </Label>
      </Form>
    </Element>
  );
}

export default EmailLoginForm;
