/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { Link } from "react-router-dom";

import Element from "../../../Element";
import Label from "../../../Label";
import Color from "../../../Color";
import Icon from "../../../Icon";
import Row from "../../../Row";

import "./Action.css";

// Action
function Action({ action, className, ...props }) {
	const cn = className ? `Action ${className}` : "Action";

	return (
		<Element className={cn}>
			{["link", "link-button"].includes(action.type) && (
				<Link to={action.link} className={action.type} {...props}>
					<Row alignItems="center" justifyContent="center">
						{action.title}
						{action.icon && (
							<Icon
								type={action.icon}
								mL={1}
								size={14}
								color={Color.Black}
							/>
						)}
					</Row>
				</Link>
			)}
			{action.type === "divider" && (
				<Label className={action.type} mL={2} mR={2}>
					/
				</Label>
			)}
		</Element>
	);
}

export default Action;
