/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// eslint-disable-next-line import/no-extraneous-dependencies
// import { useImage } from "react-image";
import { useMemo } from "react";

import Label from "../Label";
import Row from "../Row";

import "./Avatar.css";

// Avatar
function Avatar({ text, theSrc, size, alt, className, ...props }) {

    // Styles
    const { style } = props
    const styles = style ? { ...style } : {}
    if (size) {
        styles.width = `${size}px`
        styles.height = `${size}px`
    }

	// const { src: smartSrc, isLoading } = useImage({
	// 	srcList: [theSrc],
    //     useSuspense: false
	// });

    const cn = useMemo(() => {
        let result = className ? `Avatar ${className}`: "Avatar";
        if (!theSrc) {
            result += " placeholder"
        }
        return result
    }, [className, theSrc])

	if (!theSrc) {
        return (
            <Row {...props} className={cn} style={styles}>
                <Label className="text" type={Label.Type.Body} bold>
                    {text}
                </Label>
            </Row>
        )
    } 
    return <img {...props} alt={alt} className={cn} src={theSrc} style={styles} />;
    
}

export default Avatar;
