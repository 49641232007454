/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { Link } from "react-router-dom";

import { useMemo } from "react";

import { getInitialsFromFullName } from "../../../utils/user";
import AvatarBubble from "../../AvatarBubble";
import Column from "../../Column";
import Label from "../../Label";

import placeholder from "./assets/placeholder.jpg";

import "./UserGridItem.css";

// User Grid Item
function UserGridItem({
    className,
    user,
    avatarSize,
    showDisplayName,
    showFullName,
    ...props
}) {
    const cn = className ? `UserGridItem ${className}` : "UserGridItem";

    const { displayName, initials, avatarUrl, fullName } = useMemo(() => {
        let theInitials = null;
        let theFullName = null;
        let theDisplayName = null;
        // Get the display name
        if (Object.hasOwn(user, "display_name")) {
            theDisplayName = user.display_name;
        } else if (Object.hasOwn(user, "displayName")) {
            theDisplayName = user.displayName;
        } else if (Object.hasOwn(user, "user_display_name")) {
            theDisplayName = user.user_display_name;
        }
        // Get the theFullName
        if (Object.hasOwn(user, "full_name")) {
            theFullName = user.full_name;
        } else if (Object.hasOwn(user, "user_full_name")) {
            theFullName = user.user_full_name;
        } else if (Object.hasOwn(user, "user_display_name")) {
            theFullName = user.user_display_name;
        } else if (Object.hasOwn(user, "first_name")) {
            theFullName = user.first_name;
            if (user.last_name) theFullName += ` ${user.last_name}`;
        }
        // Construct the initials
        theInitials = getInitialsFromFullName(theFullName);
        // Construct the avatar url
        let photoURL = user?.image_url ?? user?.user_image_url ?? null;
        if (!photoURL && user?.msb_image_url) photoURL = user.msb_image_url;
        if (user.source !== "crawl" && !photoURL) {
            const baseStorageUrl =
                window.location.hostname !== "localhost"
                    ? `http://localhost:9199`
                    : "https://storage.googleapis.com";
            photoURL = `${baseStorageUrl}/readworthy-dev.appspot.com/users/avatars/${theDisplayName}.png`;
        }
        return {
            displayName: theDisplayName,
            initials: theInitials,
            fullName: theFullName,
            avatarUrl: photoURL,
        };
    }, [user]);

    const theAvatarSize = useMemo(() => avatarSize ?? 56, [avatarSize]);

    const { showFull, showDisplay } = useMemo(() => {
        const shouldShowFull =
            showFullName === undefined ? false : showFullName;
        const shouldShowDisplay =
            showDisplayName === undefined && !showFullName
                ? true
                : showDisplayName;
        return { showFull: shouldShowFull, showDisplay: shouldShowDisplay };
    }, [showDisplayName, showFullName]);

    const showPlaceholder = useMemo(
        () =>
            !user?.msb_image_url &&
            !user?.avatar &&
            !user?.user_image_url &&
            !user?.image_url,
        [user]
    );

    const nameToShow = useMemo(() => {
        let res = displayName;
        if (showFull && fullName) {
            res = fullName;
        }
        return res;
    }, [showFull, showDisplay]);

    return (
        <Column className={cn} {...props}>
            <Link to={`/${displayName}`}>
                {!showPlaceholder && (
                    <AvatarBubble
                        text={initials}
                        theSrc={avatarUrl}
                        size={theAvatarSize}
                    />
                )}
                {showPlaceholder && (
                    <AvatarBubble
                        text="User"
                        theSrc={placeholder}
                        size={theAvatarSize}
                    />
                )}
                <Label
                    className="display-name"
                    type={Label.Type.SmallBody}
                    bold
                    mT={2}
                >
                    {nameToShow}
                </Label>
            </Link>
        </Column>
    );
}

export default UserGridItem;
