/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useParams } from "react-router-dom";

import { useEffect, useMemo, useState } from "react";

import AddBookButton from "../../components/buttons/AddBookButton";
import MaxWidthContainer from "../../components/MaxWidthContainer";
import BookGrid from "../../components/BookGrid";
import Column from "../../components/Column";
import Label from "../../components/Label";
import Icon from "../../components/Icon";

import Page from "../../components/Page";

import { canAddBook as checkCanAddBook } from "../../utils/permissions";

import useUserProfile from "../../hooks/useUserProfile";
import useUserBooks from "../../hooks/useUserBooks";

import DashboardHeader from "./DashboardHeader";
import EmptyBookGrid from "./EmptyBookGrid";

import "./DashboardPage.css";
import Color from "../../components/Color";


// Dashboard Page
function DashboardPage({ loggedInUser, userLoading, loggedInUserIsPaid }) {

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Get the username
	const { username } = useParams();

	// Get the profile corresponding to the username
	const [vieweeProfile, vieweeProfileLoading] = useUserProfile(username);

	// Get the books belonging to the viewee
	const [books, booksLoading] = useUserBooks(vieweeProfile?.uid);
	const hasBooks = useMemo(() => books && books.length > 0, [books]);
    const [sortOrder, setSortOrder] = useState(null)

	// Is the user logged in the same as the viewee?
	const isMyDashboard = useMemo(() => {
		const isMine =
			loggedInUser &&
			vieweeProfile &&
			loggedInUser.uid === vieweeProfile.uid;
		return isMine;
	}, [loggedInUser, vieweeProfile]);

	const showAddButton = useMemo(
		() => !userLoading && isMyDashboard,
		[userLoading, isMyDashboard]
	);

	const showYourEmptyBookGrid = useMemo(
		() => !booksLoading && !hasBooks && isMyDashboard,
		[hasBooks, isMyDashboard, booksLoading]
	);

    const showSomeonesEmptyBookGrid = useMemo(
        () => !booksLoading && !hasBooks && !isMyDashboard,
		[hasBooks, isMyDashboard, booksLoading]
	);

	const canAddBook = useMemo(
		() => checkCanAddBook(loggedInUserIsPaid, books),
		[loggedInUserIsPaid, books]
	);

    // Handlers
    const handleOnChangeSortOrder = (newSortOrder) => {
        setSortOrder(newSortOrder)
    }

	return (
		<Page className="DashboardPage">
			<MaxWidthContainer>
				<Column width="100%">
					<DashboardHeader
						hasBooks={hasBooks}
                        loggedInUser={loggedInUser}
                        vieweeUserProfile={vieweeProfile}
						loading={userLoading || vieweeProfileLoading || booksLoading}
						isMyDashboard={isMyDashboard}
                        onChangeSortOrder={handleOnChangeSortOrder}
					/>
					{hasBooks && <BookGrid books={books} sortOrder={sortOrder} />}
					{showYourEmptyBookGrid && (
						<EmptyBookGrid
							isMyDashboard={isMyDashboard}
							hasBooks={hasBooks}
							canAdd={canAddBook}
						/>
					)}
                    {showSomeonesEmptyBookGrid && (
                        <Column className="no-books-yet">
                            <Icon color={Color.Black} type={Icon.Type.BookMarkLine} size={50} />
                            <Label mT={4} bold type={Label.Type.Heading3}>No Books Yet</Label>
                        </Column>
                        
                    )}
				</Column>
			</MaxWidthContainer>
			<MaxWidthContainer className="btns-footer">
				{showAddButton && <AddBookButton canAdd={canAddBook} />}
			</MaxWidthContainer>
		</Page>
	);
}

export default DashboardPage;
