/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { query, collection, where, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../clients/firebase";

// useDidUserPay hook
const useUserIsPaid = (userUid) => {
   const [didPay, setDidPay] = useState(false);
   const [loading, setLoading] = useState(true);

   // Respond to changes in userUid
   useEffect(() => {
      const fetchUserPayments = async (uid) => {
         setLoading(true);
         const q = query(
               collection(db, "stripe_customers", uid, "payments"),
               where("status", "in", ["succeeded"])
         );
         const querySnapshot = await getDocs(q);
         // Iterate the query results and determine if
         // we have a payment, setting didPay to
         // true if we do.
         querySnapshot.forEach((doc) => {
               if (doc.data()) {
                  setDidPay(true);
               }
         });
         setLoading(false);
      };

      // If we have a UID then fetch the user's
      // successful payments
      if (userUid) {
         fetchUserPayments(userUid);
      }
   }, [userUid]);

   return [didPay, loading];
};

export default useUserIsPaid;
