/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useCollection } from "react-firebase-hooks/firestore";
import { query, collection } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

// useRootBookAdders hook
const useRootBookAdders = (rootBookId) => {
	// Construct our book adder's query
	const [bookAddersQuery, setBookAddersQuery] = useState(null);
	const [books, setBooks] = useState([]);
	const [bookAddersSnapshots, bookAddersLoading, bookAddersError] =
		useCollection(bookAddersQuery);

	useEffect(() => {
		if (bookAddersSnapshots) {
			const newBooks = [];
			bookAddersSnapshots.docs.forEach((doc) => {
				newBooks.push({ id: doc.id, ...doc.data() });
			});
			setBooks(newBooks);
		}
	}, [bookAddersSnapshots]);

	// Create the query to get the user's books
	useEffect(() => {
		if (rootBookId) {
			const aQuery = query(
				collection(db, "root_books", rootBookId, "adders"),
			);
			setBookAddersQuery(aQuery);
		}
	}, [rootBookId]);

	const loading = useMemo(
		() => (bookAddersSnapshots ? bookAddersLoading : true),
		[bookAddersSnapshots, bookAddersLoading]
	);

	return [books, loading, bookAddersError];
};

export default useRootBookAdders;
