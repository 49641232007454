/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import MaxWidthContainer from "../../components/MaxWidthContainer";
import ProfileSettingsSection from "./ProfileSettingsSection";
import ProfilePhotoSection from "./ProfilePhotoSection";
import useUserProfile from "../../hooks/useUserProfile";
import Column from "../../components/Column";
import Label from "../../components/Label";
import Page from "../../components/Page";

import "./AccountPage.css";

// Account Page
function AccountPage({ loggedInUser }) {

    // Get the logged in user's profile information
	const [vieweeProfile, vieweeProfileLoading] = useUserProfile(loggedInUser?.displayName);

	return (
		<Page className="AccountPage">
			<MaxWidthContainer>
				<Column className="content" width="100%">
					<Label className="page-title" content="Account settings" />
					<ProfilePhotoSection
                        className="AccountPageSection"
						loggedInUser={loggedInUser}
                        loggedInUserProfile={vieweeProfile}
                        loggedInUserProfileLoading={vieweeProfileLoading}
					/>
                    <ProfileSettingsSection
                        className="AccountPageSection"
						loggedInUser={loggedInUser}
                        loggedInUserProfile={vieweeProfile}
                        loggedInUserProfileLoading={vieweeProfileLoading}
					/>
				</Column>
			</MaxWidthContainer>
		</Page>
	);
}

export default AccountPage;
