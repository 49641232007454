/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from '../Element';
import Loading from '../Loading';
import Label from '../Label';

import './FullPageLoader.css';


// Full Page Loader

function FullPageLoader({ className }) {
  const cn = className ? `FullPageLoader ${className}` : 'FullPageLoader';

  return (
    <Element className={cn}>
      <Loading size={40} />
      <Label className="app-name">Readworthy</Label>
    </Element>
  );
}

export default FullPageLoader;
