/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import Loading from "../../../../components/Loading";
import Element from "../../../../components/Element";
import Column from "../../../../components/Column";
import Button from "../../../../components/Button";
import Label from "../../../../components/Label";
import Icon from "../../../../components/Icon";
import Row from "../../../../components/Row";

import "./Product.css";

// Product
function Product({ product, loading, onCheckout, ...props }) {

	const { title, features, priceId } = useMemo(() => {
		const prices = product?.prices ?? [];
		const planPriceId = prices.length > 0 ? prices[0].doc_id : null;
		const planTitle = product?.metadata?.title;
		const planFeatures = product?.metadata?.features
			? product?.metadata?.features.split(",")
			: [];
		return {
			title: planTitle,
			features: planFeatures,
			priceId: planPriceId,
		};
	}, [product]);

	// Handlers
	const handleOnCheckoutWithPriceId = (stripePriceId) => {
		if (typeof onCheckout === "function") {
			onCheckout(stripePriceId);
		}
	};

	return (
		<Column className="Product" {...props}>
			<Row className="product-header">
				<Icon className="title-icon" type={Icon.Type.Pro} />
				<Label className="title" type={Label.Type.Heading4}>
					{title}
				</Label>
			</Row>
			<Element as="ul">
				{features.map((feature) => (
					<Row
						className="feature"
						key={feature}
						alignItems="flex-start"
					>
						<Icon
							className="check"
							type={Icon.Type.CheckLine}
							size={22}
							mR={2}
						/>
						<Label type={Label.Type.SmallBody}>{feature}</Label>
					</Row>
				))}
			</Element>
			<Row className="product-footer">
				{!loading && (
					<Button
                        className="checkout"
						onClick={() => handleOnCheckoutWithPriceId(priceId)}
					>
						Upgrade for $19.99
					</Button>
				)}
				{loading && (
					<Button
						disabled
                        className="checkout disabled"
						onClick={() => handleOnCheckoutWithPriceId(priceId)}
					>
						<Row>
							<Loading colorClassName="var(--grey2-color)" />
							<Label>Redirecting to checkout...</Label>
						</Row>
					</Button>
				)}
			</Row>
		</Column>
	);
}

export default Product;
