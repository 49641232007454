/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import Label from "../../../components/Label";
import Row from "../../../components/Row";

import "./PeopleHeader.css";

// People Header
function PeopleHeader({
	...props
}) {

	return (
		<Row className="PeopleHeader" {...props}>
			<Label
				type={Label.Type.Heading1}
				bold
				className="title"
			>
				All experts
			</Label>
		</Row>
	);
}

export default PeopleHeader;
