/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// import { useNavigate } from "react-router-dom";

import { useState } from "react";

import ShareDrawer from "../../ShareDrawer";
import ShareModal from "../../ShareModal";

import Button from "../../Button";
import Label from "../../Label";
import Color from "../../Color";
import Icon from "../../Icon";

import "./ShareButton.css";

// Share Button
function ShareButton({
    book,
    bookOwner,
    onChange,
    className,
    loggedInUser,
    ...props
}) {
    // const navigate = useNavigate();

    const cn = className ? `ShareButton ${className}` : `ShareButton`;

    // UI state
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isOpenShareModal, setIsOpenShareModal] = useState(false);

    // Helpers
    const toggleDrawer = () => {
        setIsOpenDrawer(!isOpenDrawer);
    };

    // Handlers
    const handleOpenShare = () => {
        if (window.innerWidth > 600) {
            setIsOpenShareModal(!isOpenShareModal);
        } else {
            toggleDrawer();
        }
    };

    return (
        <>
            <Button {...props} onClick={handleOpenShare} className={cn}>
                <Label className="btn-title">Share</Label>
                <Icon type={Icon.Type.Share} color={Color.Black} size={20} />
            </Button>
            <ShareDrawer
                book={book}
                bookOwner={bookOwner}
                isOpenDrawer={isOpenDrawer}
                onClose={toggleDrawer}
            />
            <ShareModal
                book={book}
                bookOwner={bookOwner}
                isOpen={isOpenShareModal}
                onClose={() => setIsOpenShareModal(!isOpenShareModal)}
            />
        </>
    );
}

export default ShareButton;
