/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useMemo } from "react";

import { Link, useNavigate } from "react-router-dom";

import Element from "../../../components/Element";
import Button from "../../../components/Button";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Color from "../../../components/Color";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";

import ScrollSnapChild from "../ScrollSnapChild";
import BooksColumn from "./BooksColumn";

import AvatarBubble from "../../../components/AvatarBubble";

import navalImage from "../../../assets/naval.jpg";
import barackImage from "../../../assets/barack.jpg";
import oprahImage from "../../../assets/oprah.jpg";
import elonImage from "../../../assets/elon.jpg";
import zuckImage from "../../../assets/zuck.jpg";
import bezosImage from "../../../assets/bezos.jpg";
import huffImage from "../../../assets/huff.jpg";

// import booksMp4 from "../../../assets/video/books.mp4"

import "./HeroSection.css";

// Hero Section
function HeroSection({ books, className, ...props }) {
    const cn = className ? `HeroSection ${className}` : "HeroSection";

    const navigate = useNavigate()

    const showBooksColumns = useMemo(
        () => books && books.length > 0,
        [books]
    );

    const handleScrollToFirstPage = () => {
        const snapScrollParent = document.querySelector(".ScrollSnapParent");
        if (snapScrollParent) {
            snapScrollParent.scrollTo({
                top: window.innerHeight - 55,
                left: 0,
                behavior: "smooth",
            });
        }
    };

    const { peopleUrl, registerUrl } = useMemo(() => {
        let fullUrl = window.location.href;
        if (!fullUrl.endsWith("/")) fullUrl += "/";
        const pUrl = `${fullUrl}people`;
        const rUrl = `${fullUrl}signup/username`;
        return { peopleUrl: pUrl, registerUrl: rUrl };
    }, [window.location.href]);

    return (
        <ScrollSnapChild className={cn} {...props}>
            <Column className="content" alignItems="center">
                {/* <video className="video" width="100vw" height="480px" loop autoPlay muted>
                    <track kind="captions" src="captions.vtt" label="English" default />
                    <source src={booksMp4} type="video/mp4"/>
                </video> */}
                <Label
                    className="heading"
                    type={Label.Type.Heading1}
                    color={Color.Black}
                >
                    Books recommended by the world&apos;s most successful people
                </Label>
                <Label
                    className="subheading"
                    type={Label.Type.Heading4}
                    color={Color.Black}
                >
                    Unlock the wisdom of over 700 world-class experts through
                    thousands of curated book recommendations. Dive into a
                    constantly growing library of verified insights that can
                    shape your success, all at your fingertips.
                </Label>
                {showBooksColumns && (
                    <Row className="books-column-container">
                        <BooksColumn
                            books={books.slice(0, 3)}
                            offset={0}
                            idx={1}
                        />
                        <BooksColumn
                            books={books.slice(3, 6)}
                            offset={1}
                            idx={2}
                        />
                        <BooksColumn
                            books={books.slice(6, 9)}
                            offset={2}
                            idx={3}
                        />
                        <BooksColumn
                            books={books.slice(9, 12)}
                            offset={3}
                            idx={4}
                        />
                        <BooksColumn
                            books={books.slice(12, 15)}
                            offset={2}
                            idx={5}
                        />
                        <BooksColumn
                            books={books.slice(15, 18)}
                            offset={1}
                            idx={6}
                        />
                        <BooksColumn
                            books={books.slice(18, 21)}
                            offset={0}
                            idx={7}
                        />
                    </Row>
                )}
                <Element className="search-box">
                    <div className="left" />
                    <input className="search-input" onClick={() => navigate('/people')} placeholder="Find an expert" />
                </Element>
                <Element className="people-container">
                    <Link to="/navalravikant" className="avatar-bubble-link">
                        <AvatarBubble theSrc={navalImage} size={90} />
                    </Link>
                    <Link to="/barackobama" className="avatar-bubble-link">
                        <AvatarBubble theSrc={barackImage} size={90} />
                    </Link>
                    <Link to="/oprahwinfrey" className="avatar-bubble-link">
                        <AvatarBubble theSrc={oprahImage} size={90} />
                    </Link>
                    <Link to="/elonmusk" className="avatar-bubble-link">
                        <AvatarBubble theSrc={elonImage} size={90} />
                    </Link>
                    <Link to="markzuckerberg" className="avatar-bubble-link">
                        <AvatarBubble theSrc={zuckImage} size={90} />
                    </Link>
                    <Link to="/jeffbezos" className="avatar-bubble-link">
                        <AvatarBubble theSrc={bezosImage} size={90} />
                    </Link>
                    <Link to="/ariannahuffington" className="avatar-bubble-link">
                        <AvatarBubble theSrc={huffImage} size={90} />
                    </Link>
                </Element>
                <Column className="mobile-subheadings-container">
                    <Label className="mobile-subheading">
                        🔑 Unlock wisdom from{" "}
                        <Link to={peopleUrl}>700+ influential minds</Link>
                    </Label>
                    <Label className="mobile-subheading">
                        🔍 Explore{" "}
                        <Link to={peopleUrl}>6000+ expert curated books</Link>
                    </Label>
                    <Label className="mobile-subheading">
                        📚 <Link to={registerUrl}>Curate</Link> your personal
                        bookshelf
                    </Label>
                    <Label className="mobile-subheading">
                        🥬 Discover fresh recommendations weekly
                    </Label>
                    <Label className="mobile-subheading">
                        🌐 Be part of a global reading community
                    </Label>
                </Column>
                <Element className="bottom-fade" />
                <Button
                    className="btn-how-it-works"
                    onClick={handleScrollToFirstPage}
                >
                    <Icon type={Icon.Type.ArrowDownLine} size={40} mT={1} />
                </Button>
                <Row
                    className="bottom-banner"
                    onClick={handleScrollToFirstPage}
                >
                    <Label>Here&apos;s how it works</Label>
                    <Icon type={Icon.Type.ArrowDownLine} size={30} />
                </Row>
            </Column>
        </ScrollSnapChild>
    );
}

export default HeroSection;
