/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useEffect, useState } from "react";

import { doc, getDoc } from "firebase/firestore";

import { db } from "../clients/firebase";

// useUserBook hook
const useUserBook = (userUid, bookId) => {

	const [docId, setDocId] = useState(null);
	const [userBook, setUserBook] = useState(null);

    // Some state for UX
    const [loading, setLoading] = useState(false)

	// Set the docId when we have a userUid and a bookId
	useEffect(() => {
		if (userUid && bookId) {
            setLoading(true)
			setDocId(`${userUid}_${bookId}`);
		}
	}, [userUid, bookId]);

	useEffect(() => {
        const fetchUserBook = async () => {
            const docRef = doc(db, "user_books", docId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setUserBook({ id: docSnap.id, ...docSnap.data() })
                setLoading(false)
            } else {
                console.log("No user_book document!");
                setLoading(false)
            }
        }
        if (docId) {
            fetchUserBook()
        }
	}, [docId]);

	return [userBook, loading];
};

export default useUserBook;
