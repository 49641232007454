/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from "../Element";

import "./Column.css";

// Column

function Column({ children, className, justifyContent, alignItems, ...props }) {
	const cn = className ? `Column ${className}` : "Column";

	// Add styles
	const style = props.style ? {...props.style} : {};
	if (justifyContent) {
		style.justifyContent = justifyContent;
	}
	if (alignItems) {
		style.alignItems = alignItems;
	}

	return (
		<Element {...props} className={cn} style={style}>
			{children}
		</Element>
	);
}

export default Column;
