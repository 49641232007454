/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { query, collection, getDocs } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

// useStripeProducts hook
const useStripeProducts = () => {
    const stripeProductsQuery = query(
        collection(db, "stripe_products"),
    );
    const [loadingPrices, setLoadingPrices] = useState(false)
	const [stripeProducts, setStripeProducts] = useState(null);
	const [stripeProductsSnapshot, stripeProductsLoading, stripeProductsError] =
		useCollection(stripeProductsQuery);

	useEffect(() => {

        const fetchProductPrices = async (snapshot) => {
            let results = null
            if (snapshot) {
                results = []
                setLoadingPrices(true)
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < snapshot.docs.length; i++) {
                    const productDoc = snapshot.docs[i]
                    const product = { doc_id: productDoc.id, ...productDoc.data() }
                    const prices = []
                    const pricesQuery = query(
                        collection(db, "stripe_products", productDoc.id, "prices"),
                    );
                    // eslint-disable-next-line no-await-in-loop
                    const priceSnapshot = await getDocs(pricesQuery)
                    priceSnapshot.docs.forEach((priceDoc) => {
                        prices.push({ doc_id: priceDoc.id, ...priceDoc.data() });
                    });
                    product.prices = prices
                    results.push(product);
                }
            }
            setStripeProducts(results);
        }

		if (stripeProductsSnapshot && !stripeProducts) {
			fetchProductPrices(stripeProductsSnapshot)
            setLoadingPrices(false)
		}
	}, [stripeProductsSnapshot, setStripeProducts, stripeProducts?.length, stripeProducts]);

	const loading = useMemo(
		() => (typeof stripeProductsSnapshot === "undefined" ? true : stripeProductsLoading || loadingPrices),
		[stripeProductsSnapshot, stripeProductsLoading, loadingPrices]
	);

	return [stripeProducts, loading, stripeProductsError];
};

export default useStripeProducts;
