/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import { useHits } from "react-instantsearch";
import UserGrid from "../UserGrid";

// Helpers
const transformItems = (items) =>
    items.map((item) => ({
        displayName: item.displayName,
        first_name: item.first_name,
        last_name: item.last_name,
        image_url: item?.image_url ?? item?.msb_image_url ?? null,
    }));

// User Hits
function UserHits() {
    const { items } = useHits({ transformItems });
    return <UserGrid users={items} showFullName />;
}

export default UserHits;
