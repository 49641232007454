/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Column from "../../../components/Column";

import "./ScrollSnapChild.css";

// Scroll Snap Child
function ScrollSnapChild({ children, className, ...props }) {
	const cn = className ? `ScrollSnapChild ${className}` : "ScrollSnapChild";
	return (
		<Column as="section" className={cn} {...props}>
			{children}
		</Column>
	);
}

export default ScrollSnapChild;
