/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
    EmailShareButton,
    EmailIcon,
    TwitterShareButton,
    TwitterIcon,
    FacebookShareButton,
    FacebookIcon,
    InstapaperShareButton,
    InstapaperIcon,
    PinterestShareButton,
    PinterestIcon,
    PocketShareButton,
    PocketIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import { useMemo } from "react";

import CopyLocation from "../CopyLocation";

import { getBookInfo } from "../../utils/books";

import Column from "../Column";
import Label from "../Label";
import Row from "../Row";

import "./ShareContent.css";

// Share Content
function ShareContent({
    book,
    bookOwner,
    loading,
    onClose,
    className,
    loggedInUser,
    onRequestClose,
    ...props
}) {
    const cn = className ? `ShareContent ${className}` : `ShareContent`;

    const { title: bookTitle, authorsNames: bookAuthorsNames } =
        getBookInfo(book);

    const { emailSubject, emailBody } = useMemo(() => {
        const subject = `📖 Check out ${bookTitle} on Readworthy!`;
        const body = `Here's a link:\n\n${window.location.href}`;
        return { emailSubject: subject, emailBody: body };
    }, [bookTitle, bookAuthorsNames]);

    const { title, description, imageUrl } = useMemo(() => {
        const linkTitle = `📖 ${bookTitle} by ${bookAuthorsNames} on Readworthy`;
        const linkDescription = `Here's a link:\n\n${window.location.href}`;
        const image = `https://storage.googleapis.com/${book?.images?.cover?.bucket}/books/og/og-${book.id}.png`;
        return {
            title: linkTitle,
            description: linkDescription,
            imageUrl: image,
        };
    }, [bookTitle, bookAuthorsNames]);

    return (
        <Column className={cn} {...props} width="100%">
            <Row
                className="socials-container"
                justifyContent="center"
                width="100%"
            >
                <Column justifyContent="center" alignItems="center">
                    <WhatsappShareButton
                        className="btn-social-share"
                        url={window.location.href}
                        title={title}
                    >
                        <WhatsappIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </WhatsappShareButton>
                    <Label type={Label.Type.XSmallBody}>Whatsapp</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <TwitterShareButton
                        className="btn-social-share"
                        url={window.location.href}
                        title={title}
                        hashtags={["readworthy"]}
                        related={["itsreadworthy"]}
                    >
                        <TwitterIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </TwitterShareButton>
                    <Label type={Label.Type.XSmallBody}>Twitter</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <FacebookShareButton
                        className="btn-social-share"
                        hashtag="#Readworthy"
                        url={window.location.href}
                    >
                        <FacebookIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </FacebookShareButton>
                    <Label type={Label.Type.XSmallBody}>Facebook</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <PocketShareButton
                        className="btn-social-share"
                        title={title}
                        url={window.location.href}
                    >
                        <PocketIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </PocketShareButton>
                    <Label type={Label.Type.XSmallBody}>Pocket</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <PinterestShareButton
                        className="btn-social-share"
                        description={description}
                        media={imageUrl}
                        url={window.location.href}
                    >
                        <PinterestIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </PinterestShareButton>
                    <Label type={Label.Type.XSmallBody}>Pinterest</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <TelegramShareButton
                        className="btn-social-share"
                        title={title}
                        url={window.location.href}
                    >
                        <TelegramIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </TelegramShareButton>
                    <Label type={Label.Type.XSmallBody}>Telegram</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <RedditShareButton
                        className="btn-social-share"
                        title={title}
                        url={window.location.href}
                    >
                        <RedditIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </RedditShareButton>
                    <Label type={Label.Type.XSmallBody}>Reddit</Label>
                </Column>

                <Column justifyContent="center" alignItems="center">
                    <InstapaperShareButton
                        className="btn-social-share"
                        title={title}
                        url={window.location.href}
                        description={description}
                    >
                        <InstapaperIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </InstapaperShareButton>
                    <Label type={Label.Type.XSmallBody}>Instapaper</Label>
                </Column>
                <Column justifyContent="center" alignItems="center">
                    <EmailShareButton
                        className="btn-social-share"
                        body={emailBody}
                        subject={emailSubject}
                    >
                        <EmailIcon
                            size={32}
                            round
                            bgStyle={{ fill: "transparent" }}
                            iconFillColor="black"
                        />
                    </EmailShareButton>
                    <Label type={Label.Type.XSmallBody}>Email</Label>
                </Column>
            </Row>
            <CopyLocation mT={8} onClick={onRequestClose} />
        </Column>
    );
}

export default ShareContent;
