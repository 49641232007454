/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useEffect, useMemo, useState } from "react";

import { useCollection } from "react-firebase-hooks/firestore";
import { query, collection, limit } from "firebase/firestore";

import { db } from "../clients/firebase";

// useUsers hook
const useUsers = (theLimit, whereClauseProp) => {
	// Construct our users query
	const [usersQuery, setUsersQuery] = useState(null);
	const [users, setUsers] = useState([]);
	const [usersSnapshots, usersLoading, usersError] =
		useCollection(usersQuery);

	useEffect(() => {
		if (usersSnapshots) {
			const newUsers = [];
			usersSnapshots.docs.forEach((doc) => {
				newUsers.push({ doc_id: doc.id, ...doc.data() });
			});
			setUsers(newUsers);
		}
	}, [usersSnapshots]);

    const whereClause = useMemo(() => {
        let newWhereClause = null
        if (whereClauseProp) newWhereClause = whereClauseProp
        return newWhereClause
    }, [whereClauseProp])

	// Create the query to get the users
	useEffect(() => {
		const aQuery = query(collection(db, "users"), whereClause, limit(theLimit));
		setUsersQuery(aQuery);
	}, []);

	const loading = useMemo(
		() => (usersSnapshots ? usersLoading : true),
		[usersSnapshots, usersLoading]
	);

	return [users, loading, usersError];
};

export default useUsers;
