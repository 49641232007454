/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from "../../../components/Element";

import "./ScrollSnapParent.css";

// Wave
function ScrollSnapParent({ children, className }) {
    const cn = className ? `ScrollSnapParent ${className}` : "ScrollSnapParent"

	return <Element className={cn}>{children}</Element>;
}

export default ScrollSnapParent;
