/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useParams } from "react-router-dom";

import { useEffect } from "react";

import MaxWidthContainer from "../../components/MaxWidthContainer";
import useRootBookAdders from "../../hooks/useRootBookAdders";
import BookHeader from "../../components/BookHeader";
import Page from "../../components/Page";

import useUserProfile from "../../hooks/useUserProfile";
import useUserBook from "../../hooks/useUserBook";
import useBook from "../../hooks/useBook";

import "./BookPage.css";
import Book from "./Book";
import BookLoading from "./BookLoading";

// Book Page
function BookPage({ loggedInUser }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Get the book's slug
	const { book_slug: bookSlug, username: bookOwnerDisplayName } = useParams();
	// Get the profile corresponding to the username
	const [bookOwner] = useUserProfile(bookOwnerDisplayName);
	// Get the book
	const [book, bookLoading] = useBook(bookSlug);
	// Get the user's books
	const [userBook] = useUserBook(bookOwner?.uid, book?.id);
	// Get the book's adders
	const [bookAdders] = useRootBookAdders(book?.root_book_id);

	return (
		<Page className="BookPage">
			<BookHeader
				kind="book"
				userBook={userBook}
				loggedInUser={loggedInUser}
				usernameParam={bookOwnerDisplayName}
				bookSlugParam={bookSlug}
			/>
			<MaxWidthContainer>
				{book && (
					<Book
						book={book}
						userBook={userBook}
						bookAdders={bookAdders}
						bookOwner={bookOwner}
                        loggedInUser={loggedInUser}
					/>
				)}
				{(bookLoading || !book) && <BookLoading />}
			</MaxWidthContainer>
		</Page>
	);
}

export default BookPage;
