/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Label from '../../../Label';
import Row from '../../../Row';

import './SignUpHeader.css';

// Header

function SignUpHeader({
  className, title, showBackButton, onBack, ...props
}) {
  const cn = className ? `SignUpHeader ${className}` : 'SignUpHeader';

  return (
    <Row
      alignItems="center"
      justifyContent="flex-start"
      {...props}
      className={cn}
    >
      <Label type={Label.Type.Heading4} semibold className="title">
        {title}
      </Label>
    </Row>
  );
}

export default SignUpHeader;
