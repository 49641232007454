/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from '../Element';

// Label

function Label({
  as,
  content,
  children,
  className,
  bold,
  semibold,
  type,
  useFor,
  left,
  right,
  center,
  nonBold,
  ...props
}) {
  let cn = className ? `Label ${className}` : 'Label';
  let usAs = as || 'p';
  const remainingProps = { ...props };
  if (bold) cn += ' bold';
  if (semibold) cn += ' semibold';
  if (useFor) remainingProps.for = useFor;
  if (left) cn += ' align-left';
  if (right) cn += ' align-right';
  if (center) cn += ' align-center';
  if (nonBold) cn += ' non-bold';

  if (type) {
    switch (type) {
      case 'title1':
        usAs = 'h1';
        cn += ' title';
        break;
      case 'title2':
        usAs = 'h2';
        cn += ' title';
        break;
      case 'title3':
        usAs = 'h3';
        cn += ' title';
        break;
      case 'title4':
        usAs = 'h4';
        cn += ' title';
        break;
      case 'title5':
        usAs = 'h5';
        cn += ' title';
        break;
      case 'heading-1':
        usAs = 'h1';
        break;
      case 'heading-2':
        usAs = 'h2';
        break;
      case 'heading-3':
        usAs = 'h3';
        break;
      case 'heading-4':
        usAs = 'h4';
        break;
      case 'heading-5':
        usAs = 'h5';
        break;
      case 'inner-button':
        usAs = 'span';
        cn += ' font-size-button';
        break;
      case 'tag':
        usAs = 'p';
        cn += ' font-size-tag';
        break;
      case 'hero':
        usAs = 'h1';
        cn += ' font-size-hero';
        break;
      case 'body':
        usAs = 'p';
        cn += ' font-size-body';
        break;
      case 'small-body':
        usAs = 'p';
        cn += ' font-size-small';
        break;
      case 'xsmall-body':
        usAs = 'p';
        cn += ' font-size-xsmall';
        break;
      default:
        break;
    }
  }

  return (
    <Element {...props} className={cn} as={usAs}>
      {children || content}
    </Element>
  );
}

Label.Type = {
  Body: 'body',
  Hero: 'hero',
  SmallBody: 'small-body',
  XSmallBody: 'xsmall-body',
  Heading1: 'heading-1',
  Heading2: 'heading-2',
  Heading3: 'heading-3',
  Heading4: 'heading-4',
  Heading5: 'heading-5',
  InnerButton: 'inner-button',
  Title1: 'title1',
  Title2: 'title1',
  Title3: 'title1',
  Title4: 'title1',
  Title5: 'title1',
  Tag: 'tag',
};

export default Label;
