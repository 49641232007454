/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Form, { Input, SubmitButton } from 'react-form-component';

import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isValidEmail, isValidPassword } from '../../utils';
import Loading from '../../../Loading';
import Element from '../../../Element';
import Label from '../../../Label';

import '../../Forms.css';
import './CreateUserForm.css';

// Create User Form

function CreateUserForm({
  className,
  onChange,
  onContinue,
  loading,
  buttonTitle = 'Continue',
  ...props
}) {
  const cn = className
    ? `DEFormContainer CreateUserForm ${className}`
    : 'DEFormContainer CreateUserForm';

  // Get the username from the location state is possible
  const { state } = useLocation();

  // User info
  const [password, setPassword] = useState(state?.password ?? null);
  const [email, setEmail] = useState(state?.email ?? null);

  // UI state
  const validEmail = useMemo(() => isValidEmail(email), [email]);
  const validPassword = useMemo(() => isValidPassword(password), [password]);

  const submitButtonDisabled = useMemo(() => {
    const disabled = !validEmail || !validPassword;
    return disabled;
  }, [validEmail, validPassword]);

  const buttonWrapperCn = useMemo(() => {
    const wrapperCn = 'ButtonWrapper';
    // if (submitButtonDisabled) buttonWrapperCn += ` disabled`; // Don't disable button for now
    return wrapperCn;
  }, []);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(email, password);
    }
  }, [email, onChange, password]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnContinue = () => {
    if (typeof onContinue === 'function') {
      onContinue(email, password);
    }
  };

  return (
    <Element {...props} className={cn}>
      <Form fields={['email', 'password']} onSubmit={handleOnSubmit}>
        <Input
          name="email"
          type="email"
          label="Email"
          initialValue={email}
          className={
						email && email.length > 0
						  ? 'FloatingInput filled'
						  : 'FloatingInput'
					}
          onChange={(val) => setEmail(val)}
        />
        <Input
          name="password"
          type="password"
          label="Password"
          initialValue={password}
          className={
						password && password.length > 0
						  ? 'FloatingInput filled'
						  : 'FloatingInput'
					}
          onChange={(val) => setPassword(val)}
        />
        <div className={buttonWrapperCn}>
          <SubmitButton
            disabled={submitButtonDisabled}
            className="continue"
            onClick={handleOnContinue}
          >
            {loading && <Loading colorClassName="var(--white-color)" />}
            {!loading && <Label>{buttonTitle}</Label>}
          </SubmitButton>
        </div>
      </Form>
    </Element>
  );
}

export default CreateUserForm;
