/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import { EmailShareButton } from "react-share";

import { useNavigate } from "react-router-dom";

import { getBookInfo } from "../../../utils/books";

import AmazonButton from "../../../components/buttons/AmazonButton";
import ShareButton from "../../../components/buttons/ShareButton";
import BookCover from "../../../components/BookCover";
import UserGrid from "../../../components/UserGrid";
import Element from "../../../components/Element";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";
import OptionsMenu from "./OptionsMenu";

import "./Book.css";

// Book
function Book({ book, userBook, bookAdders, bookOwner, loggedInUser }) {
    const navigate = useNavigate();

    // Format some info from the book
    const { title, subtitle, authorsNames, description, amazonLink } = useMemo(() => {
        const {
            title: bookTitle,
            subtitle: bookSubtitle,
            authorsNames: bookAuthorsNames,
            description: bookDescription,
            amazonLink: amazon,
        } = getBookInfo(book);
        return {
            title: bookTitle,
            subtitle: bookSubtitle,
            authorsNames: bookAuthorsNames,
            description: bookDescription,
            amazonLink: amazon,
        };
    }, [book]);

    const showOptionsMenu = useMemo(() => loggedInUser && loggedInUser?.uid === bookOwner?.uid, [bookOwner, loggedInUser]);

    const handleOnClickReadMore = () => {
        navigate("./description");
    };

    return (
        <Column className="Book" alignItems="center" mT={6}>
            <BookCover book={book} />
            <Label
                className="title"
                type={Label.Type.Heading1}
                bold
                center
                mT={6}
            >
                {title}
            </Label>
            <Label className="subtitle" type={Label.Type.Heading2} center>
                {subtitle}
            </Label>
            <EmailShareButton  />
            <Label
                className="authors-names"
                center
                mT={1}
                type={Label.Type.Heading3}
                bold
            >
                {authorsNames}
            </Label>
            <Row mT={7}>
                <AmazonButton link={amazonLink} />
                <ShareButton mL={2} book={book} />
            </Row>
            <Label
                className="description"
                type={Label.Type.SmallBody}
                dangerouslySetInnerHTML={{ __html: description }}
            />
            <Label
                onClick={handleOnClickReadMore}
                className="show-more"
                type={Label.Type.SmallBody}
            >
                READ MORE
            </Label>
            {bookAdders?.length > 0 && (
                <>
                    <Label className="readers" type={Label.Type.Heading4}>
                        Readers
                    </Label>
                    <UserGrid users={bookAdders} showFullName mT={2} />
                </>
            )}
            {showOptionsMenu && (
                <Element className="options-menu-container" mT={8}>
                    <OptionsMenu bookOwner={bookOwner} userBook={userBook} />
                </Element>
            )}
        </Column>
    );
}

export default Book;
