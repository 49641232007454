/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import ShareContent from "../ShareContent";

import IconButton from "../buttons/IconButton";
import Element from "../Element";
import Label from "../Label";
import Icon from "../Icon";
import Row from "../Row";

import "./ShareDrawer.css";

// Share Drawer
function ShareDrawer({
    book,
    bookOwner,
    modalTitle,
    textTitle,
    textSubtitle,
    cancelButtonTitle,
    onClose,
    onDelete,
    loggedInUser,
    className,
    loading,
    isOpenDrawer,
    ...props
}) {
    const cn = className
        ? `Drawer ShareDrawer ${className}`
        : `Drawer ShareDrawer`;

    // Handlers
    const handleOnClose = () => {
        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <Drawer
            open={isOpenDrawer}
            onClose={handleOnClose}
            direction="bottom"
            className={cn}
            duration={150}
            size="360px"
            {...props}
        >
            <Row className="header">
                <div className="spacer" />
                <Label bold type={Label.Type.Heading4}>
                    Share
                </Label>
                <Element
                    className="btn-close-container"
                    style={{ minWidth: "28px", minHeight: "28px" }}
                >
                    <IconButton
                        className="close"
                        leftIconType={Icon.Type.Close}
                        disabled={loading}
                        iconSize={28}
                        onClick={handleOnClose}
                    />
                </Element>
            </Row>
            <Element className="body">
                <ShareContent
                    book={book}
                    bookOwner={bookOwner}
                    onRequestClose={handleOnClose}
                />
            </Element>
            <Row className="footer" />
        </Drawer>
    );
}

export default ShareDrawer;
