/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// eslint-disable-next-line import/no-extraneous-dependencies
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-loading-skeleton/dist/skeleton.css";

import UserGrid from "../../../components/UserGrid";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";

import "./BookLoading.css";

// Book Loading
function BookLoading({ bookAdders }) {
	return (
		<SkeletonTheme baseColor="#ccc8c0" highlightColor="#e6e4e0">
			<Column className="BookLoading" alignItems="center" mT={6}>
				<Skeleton className="cover" width="180px" />
                <Skeleton className="title" width="276px" />
                <Skeleton className="subtitle" width="134px" />
                <Skeleton className="authors" width="128px" />
                <Row className="description-container">
                    <Skeleton className="description" count={5} width="calc(100vw - var(--spacing-8x))" />
                </Row>
				{bookAdders?.length > 0 && (
					<>
						<Label className="readers" type={Label.Type.Heading4}>
							Readers
						</Label>
						<UserGrid users={bookAdders} />
					</>
				)}
			</Column>
		</SkeletonTheme>
	);
}

export default BookLoading;
