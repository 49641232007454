/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Column from "../../../components/Column";
import NavSectionTitle from "../NavSectionTitle";

import "./NavSection.css";

// Nav Section

function NavSection({ title, children }) {
	return (
		<Column className="NavSection">
			{title && <NavSectionTitle title={title} />}
			{children}
		</Column>
	);
}

export default NavSection;
