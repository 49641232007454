/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { query, collection, orderBy } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

// useUserBookHighlights hook
const useUserBookHighlights = (userBookId) => {
	// Construct our user_book highlights query
	const [highlightsQuery, setHighlightsQuery] = useState(null);
	const [highlights, setHighlights] = useState([]);
	const [highlightsSnaps, highlightsLoading, highlightsError] =
		useCollectionOnce(highlightsQuery);

	useEffect(() => {
		if (userBookId && highlightsSnaps) {
			const newHighlights = [];
			highlightsSnaps.docs.forEach((doc) => {
				newHighlights.push({ doc_id: doc.id, ...doc.data() });
			});
			setHighlights(newHighlights);
		} else if (!userBookId) {
			setHighlights(null);
		}
	}, [userBookId, highlightsSnaps]);

	// Create the query to get the user's books
	useEffect(() => {
		if (userBookId) {
			const aQuery = query(
				collection(db, "user_books", userBookId, "highlights"), orderBy("created_at")
			);
			setHighlightsQuery(aQuery);
		} else {
			setHighlightsQuery(null);
		}
	}, [userBookId]);

	const loading = useMemo(
		() => (typeof highlightsSnaps === "undefined" ? true : highlightsLoading),
		[highlightsSnaps, highlightsLoading]
	);

	return [highlights, loading, highlightsError];
};

export default useUserBookHighlights;
