/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

import Icon from "../../Icon";

import "./AddBookButton.css";

// Add Book Button

function AddBookButton({ className, canAdd }) {
	const cn = className ? `AddBookButton ${className}` : "AddBookButton";

	// Router
	const { pathname } = useLocation();
	const navigate = useNavigate()

	const handleOnClick = () => {
        if (canAdd) {
            navigate("./add/book")
        } else {
            navigate("./upgrade")
        }
	}

	// Hide the button if we're on an "add" page
	const showButton = useMemo(() => (!pathname?.includes("add") && !pathname?.includes("upgrade")), [pathname])

	if (showButton) {
		return (
			// eslint-disable-next-line jsx-a11y/control-has-associated-label
			<button type="button" className={cn} onClick={handleOnClick}>
				<Icon type={Icon.Type.AddLine} size={30} />
			</button>
		); 
	}
	return null
}

export default AddBookButton;
