/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useEffect, useState } from "react";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { BOOK_SORT_ORDERS } from "../../../../utils/constants";
import { getSortOrder, setSortOrder } from "../../../../utils/storage";

import Element from "../../../../components/Element";
import Color from "../../../../components/Color";
import Label from "../../../../components/Label";
import Icon from "../../../../components/Icon";
import Row from "../../../../components/Row";

import "./SortMenu.css";
import IconButton from "../../../../components/buttons/IconButton";


// Sort Menu
function SortMenu({ onChange, className, loggedInUser, ...props }) {
    const cn = className ? `SortMenu ${className}` : `SortMenu`;
    const [activeSortOrder, setActiveSortOrder] = useState(null);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    useEffect(() => {
        const fetchSortOrder = async () => {
            const fetchedSortOrder = await getSortOrder(loggedInUser?.uid);
            setActiveSortOrder(fetchedSortOrder);
        };
        if (!activeSortOrder) fetchSortOrder();
    }, [activeSortOrder, loggedInUser]);

    useEffect(() => {
        if (activeSortOrder && typeof onChange === "function") {
            onChange(activeSortOrder);
        }
    }, [activeSortOrder, onChange]);

    // const activeSortOrderTitle = useMemo(() => BOOK_SORT_ORDERS.filter(order => order.value === activeSortOrder)[0].title, [activeSortOrder])

    // Handlers
    const handleOnClickMenuItem = (e) => {
        setActiveSortOrder(e.value);
        // Set the new sort order in local storage
        setSortOrder(e.value, loggedInUser?.uid);
    };

    const toggleDrawer = () => {
        setIsOpenDrawer(!isOpenDrawer);
    };

    const handleOnClickMobileMenuItem = (newOrder) => {
        setActiveSortOrder(newOrder);
        // Set the new sort order in local storage
        setSortOrder(newOrder, loggedInUser?.uid);
        // Close the drawer
        toggleDrawer();
    };

    return (
        <>
            <Element className={`${cn} desktop`} {...props}>
                <Menu
                    align="end"
                    gap={8}
                    menuButton={<MenuButton>Sort</MenuButton>}
                    {...props}
                >
                    {BOOK_SORT_ORDERS.map(({ title, value }) => {
                        const isActive = activeSortOrder === value;
                        const labelClassName = isActive
                            ? "sort-order-text active"
                            : "sort-order-text";
                        return (
                            <MenuItem
                                className="sort-order"
                                key={value}
                                value={value}
                                onClick={handleOnClickMenuItem}
                            >
                                <Row>
                                    <Label
                                        className={labelClassName}
                                        type={Label.Type.SmallBody}
                                    >
                                        {title}
                                    </Label>
                                    <Element className="sort-order-icon-container">
                                        {isActive && (
                                            <Icon
                                                className="sort-order-icon"
                                                type={Icon.Type.CheckLine}
                                            />
                                        )}
                                    </Element>
                                </Row>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Element>
            <Element className={`${cn} mobile`} {...props}>
                <Row onClick={toggleDrawer}>
                    <Label type={Label.Type.Body} color={Color.Black}>
                        Sort
                    </Label>
                    <Icon
                        type={Icon.Type.ArrowDownLine}
                        size={14}
                        color={Color.Black}
                    />
                </Row>
                <Drawer
                    open={isOpenDrawer}
                    onClose={toggleDrawer}
                    direction="bottom"
                    className="drawer"
                    duration={150}
                    size="300px"
                >
                    <Row className="header">
                        <div className="spacer" />
                        <Label bold type={Label.Type.Heading4}>
                            Sort
                        </Label>
                        <Element
                            className="btn-close-container"
                            style={{ minWidth: "28px", minHeight: "28px" }}
                        >
                            <IconButton
                                className="close"
                                leftIconType={Icon.Type.Close}
                                iconSize={28}
                                onClick={toggleDrawer}
                            />
                        </Element>
                    </Row>
                    {BOOK_SORT_ORDERS.map(({ title, value }) => {
                        const isActive = activeSortOrder === value;
                        const labelClassName = isActive
                            ? "sort-order-text active"
                            : "sort-order-text";
                        return (
                            <Element
                                className="sort-order"
                                key={value}
                                value={value}
                                onClick={() =>
                                    handleOnClickMobileMenuItem(value)
                                }
                            >
                                <Row>
                                    <Label
                                        className={labelClassName}
                                        type={Label.Type.SmallBody}
                                    >
                                        {title}
                                    </Label>
                                    <Element className="sort-order-icon-container">
                                        {isActive && (
                                            <Icon
                                                className="sort-order-icon"
                                                type={Icon.Type.CheckLine}
                                            />
                                        )}
                                    </Element>
                                </Row>
                            </Element>
                        );
                    })}
                </Drawer>
            </Element>
        </>
    );
}

export default SortMenu;
