/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import {
    getFunctions,
    httpsCallable,
    // connectFunctionsEmulator,
} from "firebase/functions";
// import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    // connectAuthEmulator,
    updateProfile,
} from "firebase/auth";
import {
    getFirestore,
    // connectFirestoreEmulator,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    setDoc,
    doc,
    getDoc,
} from "firebase/firestore";
import { handleError, USER_DOES_NOT_EXIST } from "../utils/errors";

// Config
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize our Firebase app and services
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// if (window.location.hostname === "localhost") {
//     console.log("ALERT: using DEV environment.");
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectFirestoreEmulator(db, "localhost", 5002);
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     connectStorageEmulator(storage, "localhost", 9199);
// }

// Auth

// Log out
const logout = () => {
    signOut(auth);
};

// Sign-in/Register: Google Log In
const googleProvider = new GoogleAuthProvider();
const signUpWithGoogle = async (displayName, showError = true) => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const { user } = res;
        await updateProfile(user, { displayName });
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                displayName,
                authProvider: "google",
            });
        }
    } catch (err) {
        if (showError) handleError(err);
    }
};

// Sign-in: Google
const logInWithGoogle = async (displayName, showError = true) => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const { user } = res;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            if (showError) handleError({ message: USER_DOES_NOT_EXIST });
        }
    } catch (err) {
        if (showError) handleError(err);
    }
};

// Sign-in: Email and password
const logInWithEmailAndPassword = async (email, password, showError = true) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return true;
    } catch (err) {
        if (showError) handleError(err);
        return false;
    }
};

// Register: Email and password
const registerWithEmailAndPassword = async (
    displayName,
    email,
    password,
    showError = true
) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const { user } = res;
        await updateProfile(user, { displayName });
        const userRef = doc(db, "users", `${user.uid}`);
        await setDoc(
            userRef,
            {
                uid: user.uid,
                displayName,
                authProvider: "local",
            },
            { merge: true }
        );
        return user;
    } catch (err) {
        if (showError) {
            handleError(err);
        }
        return err;
    }
};

// Reset: Email and password
const sendPasswordReset = async (email, showError = true) => {
    try {
        await sendPasswordResetEmail(auth, email);
        // eslint-disable-next-line no-alert
        alert("Password reset link sent!");
    } catch (err) {
        if (showError) handleError(err);
    }
};

// Update user profile
const updateUser = async (uid, updates, showError = true) => {
    if (!uid || !updates) return null;
    try {
        // Create a ref
        const userRef = doc(db, "users", `${uid}`);
        // Update the user
        await setDoc(
            userRef,
            {
                ...updates,
            },
            { merge: true }
        );

        const userSnap = await getDoc(userRef);
        return userSnap;
    } catch (err) {
        if (showError) {
            handleError(err);
        }
        return err;
    }
};

// Update the user's email
const updateUserEmail = async (uid, newEmail) => {
    let success = false;
    if (!uid || !newEmail) return null;
    try {
        const userRecord = await updateUser(uid, { email: newEmail });
        if (userRecord) {
            // See the UserRecord reference doc for the contents of userRecord.
            console.log(`Email update was successful!`, userRecord.toJSON());
            success = true;
        }
    } catch (error) {
        console.log("Email update failed", error);
    }
    return success;
};

// Callables
const deleteUserBookById = httpsCallable(
    functions,
    "userBooks-deleteUserBookById"
);
const lookupBookByUrl = httpsCallable(functions, "books-lookupBookByUrl");
const addBookById = httpsCallable(functions, "books-addBookById");

// Exports
export {
    app,
    auth,
    db,
    storage,
    getFunctions,
    httpsCallable,
    // Auth
    logInWithGoogle,
    signUpWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    // User
    updateUser,
    updateUserEmail,
    // Callables
    deleteUserBookById,
    lookupBookByUrl,
    addBookById,
};
