/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { SubmitButton } from "react-form-component";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactModal from "react-modal";

import { useMemo, useState } from "react";

import { addBookById, lookupBookByUrl } from "../../../clients/firebase";

import IconButton from "../../../components/buttons/IconButton";
import Element from "../../../components/Element";
import Loading from "../../../components/Loading";
import Button from "../../../components/Button";
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";

import "./AddBookModal.css";

// Add Book Modal
function AddBookModal() {
	const navigate = useNavigate();

	// Book URL
	const [url, setUrl] = useState("");
	const validUrl = useMemo(() => true, [url]);

	// UI state
	const [searching, setSearching] = useState(false);
	const [posting, setPosting] = useState(false);

	// Books to add
	const [bookToAdd, setBookToAdd] = useState(null);

	const submitButtonDisabled = useMemo(() => {
		const disabled = !validUrl;
		return disabled;
	}, [validUrl]);

	const buttonWrapperCn = useMemo(() => {
		const wrapperCn = "ButtonWrapper";
		// if (submitButtonDisabled) buttonWrapperCn += ` disabled`; // Don't disable button for now
		return wrapperCn;
	}, [submitButtonDisabled]);

	const urlCn = useMemo(() => (bookToAdd ? "url hide" : "url"), [bookToAdd]);
	const mobileLookupCn = useMemo(
		() => (bookToAdd ? "mobile-continue hide" : "mobile-continue"),
		[bookToAdd]
	);
	const desktopLookupCn = useMemo(
		() => (bookToAdd ? "desktop-continue hide" : "mobile-continue"),
		[bookToAdd]
	);

	const { bookToAddImage, bookToAddTitle, bookToAddAuthors } = useMemo(() => {
		const title = bookToAdd?.title ?? "";
		const image = bookToAdd?.image ?? null;
		const authors = bookToAdd?.authors ? bookToAdd.authors.join(", ") : "";
		return {
			bookToAddImage: image,
			bookToAddTitle: title,
			bookToAddAuthors: authors,
		};
	}, [bookToAdd]);

	// Handlers
	const handleOnOverrideSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleOnLookup = async () => {
		setSearching(true);
		const res = await lookupBookByUrl({ url });
		if (res?.data?.success) {
			setBookToAdd(res.data.book);
		}
		setSearching(false);
	};

	const handleOnPost = async () => {
		setPosting(true);
		// Post the book
		const res = await addBookById({ book_id: bookToAdd.id });
		if (res?.data?.success) {
			setPosting(false);
			// Toast the success & return
			navigate(-1);
		}
		setPosting(false);
	};

	const handleReset = () => {
		setUrl("");
		setBookToAdd(null);
	};

	const handleOnClose = () => {
		navigate(-1);
	};

	return (
		<ReactModal
			isOpen
			className="Modal AddBookModal"
			overlayClassName="ModalOverlay AddBookModalOverlay"
		>
			<form className="form-add-book" onSubmit={handleOnOverrideSubmit}>
				<Column className="content">
					<Row className="header">
						<IconButton
							className="close"
							leftIconType={Icon.Type.Close}
							iconSize={28}
							onClick={handleOnClose}
						/>
						<IconButton
							className="back"
							leftIconType={Icon.Type.LineArrowLeft}
							iconSize={28}
							onClick={handleOnClose}
						/>
						{bookToAdd && (
							<Button disabled={posting} className="post" onClick={handleOnPost}>
								{posting ? (
									<Loading colorClassName="var(--white-color)" />
								) : (
									<Label>Post book</Label>
								)}
							</Button>
						)}
					</Row>
					<Element className="body">
						<textarea
							min={1}
							name="url"
							type="text"
							placeholder="Copy and paste the Amazon URL of a book"
							className={urlCn}
							value={url}
							contentEditable
							onChange={(e) => setUrl(e.target.value)}
						/>
						{bookToAdd && (
							<Row className="book-info" alignItems="center">
								<img
									className="book-cover"
									src={bookToAddImage}
									alt="book-cover"
								/>
								<Column
									alignItems="flex-start"
									justifyContent="center"
								>
									<Label className="book-title">
										{bookToAddTitle}
									</Label>
									<Label className="book-authors">
										{bookToAddAuthors}
									</Label>
									<IconButton
										className="reset"
										mT={4}
										leftIconType={Icon.Type.Delete}
										title="Remove and search again"
										onClick={handleReset}
									/>
								</Column>
							</Row>
						)}
						{!bookToAdd && (
							<Button
								disabled={false}
								className={mobileLookupCn}
								onClick={handleOnLookup}
							>
								{searching ? (
									<Loading colorClassName="var(--white-color)" />
								) : (
									<Label>Lookup book</Label>
								)}
							</Button>
						)}
					</Element>
					<Row className="footer">
						{!bookToAdd && (
							<div className={buttonWrapperCn}>
								<SubmitButton
									disabled={false}
									className={desktopLookupCn}
									onClick={handleOnLookup}
								>
									{searching ? (
										<Loading colorClassName="var(--white-color)" />
									) : (
										<Label>Lookup book</Label>
									)}
								</SubmitButton>
							</div>
						)}
					</Row>
				</Column>
			</form>
		</ReactModal>
	);
}

export default AddBookModal;
