/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import {
	BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST,
	BOOK_SORT_ORDER_DATE_ADDED_OLDEST_TO_NEWEST,
	BOOK_SORT_ORDER_TITLE_A_TO_Z,
	BOOK_SORT_ORDER_TITLE_Z_TO_A,
} from "../../utils/constants";

import BookGridItem from "./BookGridItem";
import Element from "../Element";

import "./BookGrid.css";

// Book Grid
function BookGrid({ className, books, sortOrder, noOwner, ...props }) {
	const cn = className ? `BookGrid ${className}` : "BookGrid";

	const sortedBooks = useMemo(() => {
		let sorted = books && books.length > 0 ? books : [];
		if (sorted) {
			switch (sortOrder) {
				case BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST: {
					sorted = sorted.sort((a, b) => b.created_at - a.created_at);
					break;
				}
				case BOOK_SORT_ORDER_DATE_ADDED_OLDEST_TO_NEWEST: {
					sorted = sorted.sort((a, b) => a.created_at - b.created_at);
					break;
				}
				case BOOK_SORT_ORDER_TITLE_A_TO_Z: {
					sorted = sorted.sort((a, b) =>
						a.book_title
							.toLowerCase()
							.localeCompare(b.book_title.toLowerCase())
					);
					break;
				}
				case BOOK_SORT_ORDER_TITLE_Z_TO_A: {
					sorted = sorted.sort((a, b) =>
						b.book_title
							.toLowerCase()
							.localeCompare(a.book_title.toLowerCase())
					);

					break;
				}
				default:
					break;
			}
		}
		return sorted;
	}, [books, sortOrder]);

	return (
		<Element as="ul" className={cn} {...props}>
			{sortedBooks.map((userBook) => (
				<BookGridItem
					as="li"
					key={`bgi-${userBook?.book_id ?? userBook?.id }`}
					book={userBook}
                    noOwner={noOwner}
				/>
			))}
		</Element>
	);
}

export default BookGrid;
