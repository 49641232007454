/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useMemo } from "react";

import AvatarBubble from "../../../components/AvatarBubble";

import { getInitialsFromFullName } from "../../../utils/user";

import Column from "../../../components/Column";
import Color from "../../../components/Color";
import Label from "../../../components/Label";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";
import SortMenu from "./SortMenu";

import "./DashboardHeader.css";

// Dashboard Header
function DashboardHeader({
    loggedInUser,
    vieweeUserProfile,
    loading,
    isMyDashboard,
    hasBooks,
    onChangeSortOrder,
    ...props
}) {
    const { userFirstName } = useMemo(() => {
        let firstName = null;
        if (vieweeUserProfile) {
            firstName = vieweeUserProfile.first_name ?? null;
        }
        return { userFirstName: firstName };
    }, [vieweeUserProfile]);

    const titleText = useMemo(() => {
        if (loading) return "";
        let title = "";
        let emptyTitle = "You haven't added any books...";
        let nonEmptyTitle = "What you've been reading";
        if (!isMyDashboard) {
            emptyTitle = userFirstName
                ? `${userFirstName} hasn't recommended any books...`
                : `This user hasn't recommended any books...`;
            nonEmptyTitle = userFirstName
                ? `Recommended by ${userFirstName}`
                : "Recommendations";
        }
        title = hasBooks ? nonEmptyTitle : emptyTitle;
        return title;
    }, [userFirstName, loading, hasBooks, isMyDashboard]);

    const { crawled, initials, avatarUrl, fullName, description } =
        useMemo(() => {
            let photoURL = null;
            let theInitials = null;
            let theFullName = null;
            let theDescription = null;
            let theDisplayName = null;
            const isCrawled = vieweeUserProfile?.source === "crawl";
            // Get the display name
            if (vieweeUserProfile) {
                if (Object.hasOwn(vieweeUserProfile, "display_name")) {
                    theDisplayName = vieweeUserProfile.display_name;
                } else if (Object.hasOwn(vieweeUserProfile, "displayName")) {
                    theDisplayName = vieweeUserProfile.displayName;
                } else if (
                    Object.hasOwn(vieweeUserProfile, "user_display_name")
                ) {
                    theDisplayName = vieweeUserProfile.user_display_name;
                }
                // Get the theFullName
                if (Object.hasOwn(vieweeUserProfile, "full_name")) {
                    theFullName = vieweeUserProfile.displayName;
                } else if (Object.hasOwn(vieweeUserProfile, "user_full_name")) {
                    theFullName = vieweeUserProfile.user_display_name;
                } else if (Object.hasOwn(vieweeUserProfile, "first_name")) {
                    theFullName = vieweeUserProfile.first_name;
                    if (vieweeUserProfile.last_name)
                        theFullName += ` ${vieweeUserProfile.last_name}`;
                }
                // Construct the initials
                theInitials = getInitialsFromFullName(theFullName);
                // Construct the avatar url
                photoURL =
                    vieweeUserProfile?.image_url ??
                    vieweeUserProfile?.user_image_url ??
                    null;
                if (!photoURL && vieweeUserProfile?.msb_image_url)
                    photoURL = vieweeUserProfile.msb_image_url;
                if (vieweeUserProfile.source !== "crawl" && !photoURL) {
                    const baseStorageUrl =
                        window.location.hostname !== "localhost"
                            ? `http://localhost:9199`
                            : "https://storage.googleapis.com";
                    photoURL = `${baseStorageUrl}/readworthy-dev.appspot.com/users/avatars/${theDisplayName}.png`;
                }
                theDescription =
                    vieweeUserProfile?.bio?.replace(" Wikipedia", " ") ?? null;
            }
            return {
                crawled: isCrawled,
                description: theDescription,
                displayName: theDisplayName,
                initials: theInitials,
                fullName: theFullName,
                avatarUrl: photoURL,
            };
        }, [vieweeUserProfile]);

    const { instagram, youtube, twitter, wikipedia, website, showSocials } =
        useMemo(() => {
            const insta = vieweeUserProfile?.instagram_url ?? null;
            const utube = vieweeUserProfile?.youtube_url ?? null;
            const twitt = vieweeUserProfile?.twitter_url ?? null;
            const wiki = vieweeUserProfile?.wikipedia_url ?? null;
            const web = vieweeUserProfile?.website_url ?? null;
            return {
                instagram: insta,
                youtube: utube,
                twitter: twitt,
                wikipedia: wiki,
                website: web,
                showSocials: insta || utube || twitt || wiki || web,
            };
        }, [vieweeUserProfile]);

    // Handlers
    const handleOnChangeSortOrder = (sortOrder) => {
        if (typeof onChangeSortOrder === "function") {
            onChangeSortOrder(sortOrder);
        }
    };

    const handleOnClickSocial = (social) => {
        let forwardingUrl = social;
        if (forwardingUrl.startsWith("@")) {
            forwardingUrl = `https://youtube.com/${social}`;
        } else if (!forwardingUrl.startsWith("http")) {
            forwardingUrl = `https://${social}`;
        }
        window.open(forwardingUrl, "_blank");
    };

    return (
        <Row className="DashboardHeader" {...props}>
            {crawled && (
                <Row className="top">
                    <AvatarBubble
                        text={initials}
                        theSrc={avatarUrl}
                        size={100}
                    />
                    <Column className="info">
                        <Label
                            className="full-name"
                            bold
                            type={Label.Type.Heading3}
                            mL={4}
                        >
                            {fullName}&apos;s{" "}
                            <span className="recs">book recommendations</span>
                        </Label>
                        {description && (
                            <Label
                                className="description"
                                type={Label.Type.Body}
                                mL={4}
                            >
                                {description}
                                {wikipedia ? (
                                    <a
                                        href={wikipedia}
                                        target="_window"
                                        className="wiki-link"
                                    >{` Wikipedia.`}</a>
                                ) : (
                                    ""
                                )}
                            </Label>
                        )}
                        {showSocials && (
                            <Row className="socials" alignItems="center">
                                {twitter && (
                                    <Icon
                                        type={Icon.Type.Twitter}
                                        size={20}
                                        color={Color.Black}
                                        touchable
                                        onClick={() =>
                                            handleOnClickSocial(twitter)
                                        }
                                    />
                                )}

                                {instagram && (
                                    <Icon
                                        type={Icon.Type.Instagram}
                                        size={20}
                                        color={Color.Black}
                                        touchable
                                        onClick={() =>
                                            handleOnClickSocial(instagram)
                                        }
                                    />
                                )}
                                {youtube && (
                                    <Icon
                                        type={Icon.Type.Youtube}
                                        size={20}
                                        color={Color.Black}
                                        touchable
                                        onClick={() =>
                                            handleOnClickSocial(youtube)
                                        }
                                    />
                                )}
                                {website && (
                                    <Icon
                                        type={Icon.Type.Link}
                                        size={18}
                                        color={Color.Black}
                                        touchable
                                        onClick={() =>
                                            handleOnClickSocial(website)
                                        }
                                    />
                                )}
                            </Row>
                        )}
                    </Column>
                </Row>
            )}
            {!crawled && (
                <Label
                    type={Label.Type.Heading1}
                    bold
                    className="title"
                    mB={hasBooks ? 0 : 8}
                >
                    {titleText}
                </Label>
            )}
            {!loading && hasBooks && (
                <SortMenu
                    loggedInUser={loggedInUser}
                    onChange={handleOnChangeSortOrder}
                    mT={2}
                />
            )}
        </Row>
    );
}

export default DashboardHeader;
