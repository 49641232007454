// Sort orders for books
export const BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST = "date-added-newest-to-oldest"
export const BOOK_SORT_ORDER_DATE_ADDED_OLDEST_TO_NEWEST = "date-added-oldest-to-newest"
export const BOOK_SORT_ORDER_TITLE_A_TO_Z = "title-a-to-z"
export const BOOK_SORT_ORDER_TITLE_Z_TO_A = "title-z-to-a"

export const BOOK_SORT_ORDERS = [
    { title: "Date added (newest first)", value: BOOK_SORT_ORDER_DATE_ADDED_NEWEST_TO_OLDEST },
    { title: "Date added (oldest first)", value: BOOK_SORT_ORDER_DATE_ADDED_OLDEST_TO_NEWEST },
    { title: "Title (A-Z)", value: BOOK_SORT_ORDER_TITLE_A_TO_Z },
    { title: "Title (Z-A)", value: BOOK_SORT_ORDER_TITLE_Z_TO_A },
]