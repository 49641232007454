/** @format */

import { Sling as Hamburger } from "hamburger-react";
import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Element from "../../../../components/Element";

// Main Menu Hamburger Button
function MainMenuHamburgerButton({
	className,
	duration = 0.4,
	size = 30,
	...props
}) {

	const isNav = window.location.pathname.endsWith("nav")
	const location = useLocation()

	const navigate = useNavigate(0)

	// UI state
	const [isOpen, setIsOpen] = useState(isNav);
	const cn = className
		? `MainMenuHamburgerButton ${className}`
		: "MainMenuHamburgerButton";

	// Watch the route to determine whethet to open/close the button
	useEffect(() => {
		if (location?.pathname?.includes("/nav") && !isOpen) {
			setIsOpen(true)
		} else if (!location?.pathname?.includes("/nav") && isOpen) {
			setIsOpen(false)
		}
	}, [isOpen, location?.pathname])

	// Handlers
	const handleToggle = () => {
		if (isOpen) {
			setIsOpen(false);
			navigate(-1);
		} else {
			setIsOpen(true)
			navigate("./nav");
		}
	};

	return (
		<Element className={cn} {...props}>
			<Hamburger
				duration={duration}
				size={size}
				toggled={isOpen}
				toggle={handleToggle}
			/>
		</Element>
	);
};

export default MainMenuHamburgerButton;
