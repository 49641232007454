/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo, useState } from "react";

import { getMetadata, ref, uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";

import { updateUserData } from "../../../utils/user";
import { storage } from "../../../clients/firebase";

import Element from "../../Element";
import Button from "../../Button";

import "./UploadAvatarForm.css";

// Upload Avtar Form
function UploadAvatarForm({ loggedInUser }) {
	const [, setResponse] = useState(null);
	const [, setLoading] = useState(false);
	const [, setError] = useState(null);

	const fileRef = useMemo(() => {
		const theFileRef = loggedInUser
			? ref(storage, `users/avatars/${loggedInUser?.displayName}.png`)
			: null;
		return theFileRef;
	}, [loggedInUser]);

	const hasPhoto = useMemo(() => loggedInUser?.photoURL, [loggedInUser]);

	const uploadFile = async (file) => {
		try {
			const uploadRes = await uploadBytes(fileRef, file);
			// Set some state base on response
			setResponse(uploadRes);
			setLoading(false);
			setError(null);
			// Update the user's profile
			const metadata = await getMetadata(fileRef);
			const baseStorageUrl =
				window.location.hostname !== "localhost"
					? `http://localhost:9199`
					: "https://storage.googleapis.com";
			const photoURL = `${baseStorageUrl}/${metadata.bucket}/${metadata.fullPath}`;
			updateProfile(loggedInUser, { photoURL });
			updateUserData(loggedInUser.uid, {
				avatar: {
					bucket: metadata.bucket,
					fullPath: metadata.fullPath,
					photoURL,
				},
			});
		} catch (err) {
			setResponse(null);
			setLoading(false);
			setError(err);
		}
	};

	return (
		<Element className="UploadAvatarForm">
			<label htmlFor="file-upload" className="fileUpload">
				{hasPhoto ? "Change" : "Upload"}
				<input
					id="file-upload"
					type="file"
					onChange={(e) => {
						uploadFile(e.target.files[0]);
					}}
				/>
			</label>

			{hasPhoto && (
				<Button className="fileRemove" mL={2}>
					Remove
				</Button>
			)}
		</Element>
	);
}

export default UploadAvatarForm;
