/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// import { useLocation } from "react-router-dom";

import { toastIt } from "../../utils/alerts";

import Label from "../Label";
import Row from "../Row";

import "./CopyLocation.css";

// Copy Location
function CopyLocation({ loading, onClose, className, loggedInUser, onClick, ...props }) {
    const cn = className ? `CopyLocation ${className}` : `CopyLocation`;

    const handleOnClick = () => {
        navigator.clipboard.writeText(window.location.href);
        toastIt("Link copied", "success");
        if (typeof onClick === "function") {
            onClick()
        }
    };

    return (
        <Row className={cn} {...props} onClick={handleOnClick}>
            <Label className="prompt" bold>
                Copy link
            </Label>
            <Label className="link">{window.location.href}</Label>
        </Row>
    );
}

export default CopyLocation;
