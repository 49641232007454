/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useEffect, useMemo } from "react";
import Element from "../Element";

import "./BookCover.css";

// Book Cover
function BookCover({
	className,
	book,
	animateIn,
	animateDelay,
	idx,
	imgClassName,
	...props
}) {
	const cn = className ? `BookCover ${className}` : "BookCover";
	const imgCn = imgClassName ? `img-cover ${imgClassName}` : `img-cover`;

	useEffect(() => {
		let timeout = null;
		if (animateIn && animateDelay) {
			// Get the image
			timeout = setTimeout(() => {
				const imgEl = document.querySelector(`.img-${idx}`);
				imgEl.classList.toggle("animateIn");
			}, [animateDelay * 50]);
		}
		return () => clearTimeout(timeout);
	}, [animateIn, animateDelay, idx]);

    const { fallbackImageSrc } = useMemo(() => {
        const baseStorageUrl = window.location.hostname === "shkjm" ? `http://localhost:9199` : 'https://storage.googleapis.com'
        const bookId = book?.user_uid ? book.book_id : book.id
        const bookImageSrc = `${baseStorageUrl}/${process.env.REACT_APP_STORAGE_BUCKET}/books/covers/${bookId}.jpg`
        return { imageSrc: bookImageSrc, fallbackImageSrc: book?.image }
    }, [book])
    
    const setFallbackSrc = (e) => {
        e.target.src = fallbackImageSrc
        return null
    }

	return (
		<Element key={`cover-${idx}`} className={cn} {...props}>
			<figure className="cover">
				<img alt={book?.name} src={fallbackImageSrc} className={imgCn} onError={setFallbackSrc} />
			</figure>
		</Element>
	);
}

export default BookCover;
