/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../clients/firebase";

// Update User Data
export const updateUserData = async (userUid, updates) => {
	const userRef = doc(db, "users", userUid);
	const res = await setDoc(
		userRef,
		{ ...updates, updated_at: serverTimestamp() },
		{ merge: true }
	);
    return res
};

export const getInitialsFromFullName = (fullName) => {
    let result = null
    if (!fullName || fullName.fullName === 0) return result
    const splitFullName = fullName.split(" ")
    const [first, second] = splitFullName
    if (first) {
        const [firstNameFirstLetter] = first
        result = firstNameFirstLetter
    }
    if (second) {
        const [secondNameFirstLetter] = second
        result += ` ${secondNameFirstLetter}` 
    }
    return result
}