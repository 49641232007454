/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import ClipLoader from 'react-spinners/ClipLoader';
import { useMemo } from 'react';

import Row from '../Row';

import './Loading.css';

// Loading

function Loading({ colorClassName, size = 24 }) {
  const color = useMemo(() => colorClassName ?? 'var(--black-color)', [colorClassName]);

  return (
    <Row className="Loading" alignItems="center" justifyContent="center">
      <ClipLoader
        size={size}
        color={color}
      />
    </Row>
  );
}

export default Loading;
