/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { Link } from "react-router-dom";

import { useMemo } from "react";

import { getBookInfo, getUserBookInfo } from "../../../utils/books";

import Column from "../../Column";
import Label from "../../Label";

import "./BookGridItem.css";

// Book Grid Item
function BookGridItem({ className, book, noOwner, ...props }) {
	const cn = className ? `BookGridItem ${className}` : "BookGridItem";

    const { title, imageSrc, fallbackImageSrc } = useMemo(() => {
        const { title: bookTitle, image: bookFallbackImageSrc } = book?.user_uid ? getUserBookInfo(book) : getBookInfo(book)
        const baseStorageUrl = window.location.hostname === "localhost" ? `http://localhost:9199` : 'https://storage.googleapis.com'
        const bookId = book?.user_uid ? book.book_id : book.id
        const bookImageSrc = `${baseStorageUrl}/readworthy-dev.appspot.com/books/covers/${bookId}.jpg`
        return { title: bookTitle, imageSrc: bookImageSrc, fallbackImageSrc: bookFallbackImageSrc  }
    }, [book])

    const setFallbackSrc = (e) => {
        e.target.src = fallbackImageSrc
        return null
    }

    const bookSlug = useMemo(() => book?.book_slug ?? book.slug, [book])
    const bookLink = useMemo(() =>  noOwner ? `all/books/${bookSlug}` : `books/${bookSlug}`, [bookSlug, noOwner])

	return (
		<Column className={cn} {...props}>
            <Link to={bookLink}>
                <figure className="cover">
                    <img alt={title} src={imageSrc} onError={setFallbackSrc} />
                </figure>
                <Label type={Label.Type.SmallBody} bold>{title}</Label>
            </Link>
		</Column>
	);
}

export default BookGridItem;
