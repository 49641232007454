/* eslint-disable react/button-has-type */
/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import AmazonSvg from "./amazon.svg";
import Button from "../../Button";

import "./AmazonButton.css";
import Label from "../../Label";

// Amazon Button
function AmazonButton({ className, link, ...props }) {
	const cn = className ? `AmazonButton ${className}` : "AmazonButton";

    const handleOnClick = () => {
        window.open(link, "_blank");
    }

	return (
		<Button {...props} className={cn} onClick={handleOnClick}>
            <Label className="btn-title">Buy</Label>
			<img src={AmazonSvg} alt="amazon logo" width="16" height="16" />
		</Button>
	);
}

export default AmazonButton;
