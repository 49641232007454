/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import ReactModal from "react-modal";
// import { useState } from "react";

import IconButton from "../buttons/IconButton";
import Element from "../Element";
import Loading from "../Loading";
import Column from "../Column";
import Button from "../Button";
import Label from "../Label";
import Icon from "../Icon";
import Row from "../Row";

import "./DeleteModal.css";

// Delete Modal
function DeleteModal({
	modalTitle,
	textTitle,
	textSubtitle,
	cancelButtonTitle,
	deleteButtonTitle,
	onClose,
	onDelete,
	loggedInUser,
	className,
	loading,
	...props
}) {
	const cn = className
		? `Modal DeleteModal ${className}`
		: `Modal DeleteModal`;

	// Handlers
	const handleOnClose = () => {
		if (typeof onClose === "function") {
			onClose();
		}
	};

	const handleOnDelete = () => {
		if (typeof onDelete === "function") {
			onDelete();
		}
	};

	return (
		<ReactModal
			isOpen
			className={`${cn}`}
            onRequestClose={handleOnClose}
			overlayClassName="ModalOverlay DeleteModalOverlay"
			{...props}
		>
			<Column>
				<Row className="header">
					<div className="spacer" />
					<Label bold type={Label.Type.Heading4}>
						{modalTitle}
					</Label>
					<IconButton
						className="close"
						leftIconType={Icon.Type.Close}
						disabled={loading}
						iconSize={28}
						onClick={handleOnClose}
					/>
				</Row>
				<Element className="body">
					<Label bold type={Label.Type.Body}>
						{textTitle}
					</Label>
					<Label mT={2} type={Label.Type.Body}>
						{textSubtitle}
					</Label>
				</Element>
				<Row className="footer">
					<Button
						disabled={loading}
						mR={2}
						className="nevermind"
						onClick={handleOnClose}
					>
						{cancelButtonTitle}
					</Button>
					<Button
						disabled={loading}
						mL={2}
						className="confirm-negative"
						onClick={handleOnDelete}
					>
						{!loading ? deleteButtonTitle : <Loading colorClassName="var(--white-color)" />}
					</Button>
				</Row>
			</Column>
		</ReactModal>
	);
}

export default DeleteModal;
