/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useMemo } from "react";

import { useParams } from "react-router-dom";

import MaxWidthContainer from "../MaxWidthContainer";
import Actions from "./Actions";
import Crumbs from "../Crumbs";
import Row from "../Row";

import useUserProfile from "../../hooks/useUserProfile";
import useBook from "../../hooks/useBook";

import "./BookHeader.css";

// BookHeader
function BookHeader({
    kind,
    className,
    userBook,
    loggedInUser,
    usernameParam,
    bookSlugParam,
    ...props
}) {
    const cn = className ? `BookHeader ${className}` : "BookHeader";

    // Get the user's displayName, and the book's slug
    const { book_slug: bookSlug, username: bookOwnerDisplayName } = useParams();

    // Get the book
    const [book] = useBook(bookSlug);

    // Get the profile corresponding to the username
    const [bookOwner] = useUserProfile(bookOwnerDisplayName);

    const canAddHighlights = useMemo(
        () => loggedInUser && loggedInUser?.uid === bookOwner?.uid,
        [loggedInUser, bookOwner]
    );

    return (
        <Row className={cn} {...props}>
            <MaxWidthContainer>
                <Crumbs
                    kind={kind}
                    book={book}
                    bookOwner={bookOwner}
                    userBook={userBook}
                    usernameParam={usernameParam}
                    bookSlugParam={bookSlugParam}
                />
                <Actions
                    kind={kind}
                    book={book}
                    bookOwner={bookOwner}
                    userBook={userBook}
                    canAddHighlights={canAddHighlights}
                />
            </MaxWidthContainer>
        </Row>
    );
}

export default BookHeader;
