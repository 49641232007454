/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { useCollectionData } from 'react-firebase-hooks/firestore';
import { query, collection, where } from 'firebase/firestore';

import { useEffect, useMemo, useState } from 'react';

import { db } from '../clients/firebase';

// useUserProfile hook

const useUserProfile = (lookup) => {

  const [userProfileQuery, setUserProfileQuery] = useState(null);
  const [userProfile, userProfileLoading, userProfileError] = useCollectionData(userProfileQuery);

  const { lookupValue, lookupKey } = useMemo(() => {
    const key = typeof lookup === 'string' ? 'displayName' : 'uid';
    const value = lookup
    return { lookupValue: value, lookupKey: key };
  }, [lookup]);

  // Construct our users query
  useEffect(() => {
    if (lookupKey && lookupValue) {
      const aQuery = query(collection(db, 'users'), where(lookupKey, '==', lookupValue));
      setUserProfileQuery(aQuery);
    }
  }, [lookupKey, lookupValue]);

  const res = useMemo(() => userProfile && userProfile.length > 0 ? userProfile[0] : null, [userProfile])
  const loading = useMemo(() => !userProfile || userProfileLoading, [userProfile, userProfileLoading])

  return [res, loading, userProfileError];
};

export default useUserProfile;
