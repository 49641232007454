/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Form, { Input, SubmitButton } from "react-form-component";

import { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import useIsUsernameAvailable from "../../../../hooks/useIsUsernameAvailable";
import Loading from "../../../Loading";
import Element from "../../../Element";
import Label from "../../../Label";
import Color from "../../../Color";
import Icon from "../../../Icon";
import { PEOPLE } from "./utils";

import "../../Forms.css";
import "./UpdateUsernameForm.css";
import { isValidUsername } from "../../utils";

// Update Username Form

function UpdateUsernameForm({
	className,
	onChange,
	onContinue,
	loading,
	buttonTitle = "Continue",
	...props
}) {
	const cn = className
		? `DEFormContainer UpdateUsernameForm ${className}`
		: "DEFormContainer UpdateUsernameForm";

	// Get the username from the location state is possible
	const { state } = useLocation();

	// User info
	const [username, setUsername] = useState(state?.username ?? null);

	// Username Availability
	const [availUsername, availUsernameLoading] =
		useIsUsernameAvailable(username);

	// UI State management
	const validUsername = useMemo(() => {
		const valid =
			!availUsernameLoading && availUsername && isValidUsername(username);
		return valid;
	}, [username, availUsernameLoading, availUsername]);

	const submitButtonDisabled = useMemo(() => {
		const disabled = !validUsername;
		return disabled;
	}, [validUsername]);

	const buttonWrapperCn = useMemo(() => {
		const wrappedCn = "ButtonWrapper";
		// if (submitButtonDisabled) buttonWrapperCn += ` disabled` // Don't disable button for now
		return wrappedCn;
	}, [submitButtonDisabled]);

	const placeholder = useMemo(() => {
		const random = Math.floor(Math.random() * PEOPLE.length);
		return PEOPLE[random];
	}, []);

	// Handlers
	const handleOnSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleOnContinue = () => {
		if (validUsername && typeof onContinue === "function") {
			onContinue();
		} else {
			toast.error("Please provide a valid username.", {
				duration: 4000,
				position: "top-center",
			});
		}
	};

	const usernameInputSuffix = useMemo(() => {
		let result = null;
		if (availUsernameLoading) {
			result = <Loading className="username-loading" />;
		} else if (username?.length > 3 && isValidUsername) {
			result = (
				<Icon
					type={Icon.Type.CheckmarkCircleFilled}
					color={Color.Green}
					size={20}
				/>
			);
		} else if (username?.length > 3) {
			result = (
				<Icon type={Icon.Type.CloseCircleLine} color={Color.Red} />
			);
		}
		return result;
	}, [availUsernameLoading, username]);

	// Callbacks
	useEffect(() => {
		onChange(username);
	}, [onChange, username]);

	return (
		<Element {...props} className={cn}>
			<Form
				className="position-relative"
				fields={["username"]}
				onSubmit={handleOnSubmit}
			>
				<Input
					type="username"
					name="username"
					label="Username"
					placeholder={placeholder}
					className={
						username && username.length > 0
							? "StickyPlaceholderInput"
							: "StickyPlaceholderInput"
					}
					initialValue={username}
					onChange={(val) => setUsername(val)}
					suffix={usernameInputSuffix}
				/>
				<Label className="StickyPlaceholder">readworthy.co/</Label>
				<div className={buttonWrapperCn}>
					<SubmitButton
						disabled
						className="continue"
						onClick={handleOnContinue}
					>
						{loading && <Loading />}
						{!loading && <Label>{buttonTitle}</Label>}
					</SubmitButton>
				</div>

				<Label className="already-link">
					Have an account?{" "}
					<NavLink className="text-link" to="/login">
						Log in
					</NavLink>
				</Label>
			</Form>
		</Element>
	);
}

export default UpdateUsernameForm;
