/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Icon from "../../../components/Icon";
import Label from "../../../components/Label";
import Row from "../../../components/Row";
import "./NavTextButton.css";

// Nav Text Button

function NavTextButton({
	text,
	className,
	iconType,
	iconColor,
	theme,
	onClick,
	...props
}) {
	let cn = className ? `NavTextButton ${className}` : `NavTextButton`;
	const themeAddOn = theme ?? "light";
	cn += ` ${themeAddOn}`;

	// Handlers

	const handleOnClick = (e) => {
		if (typeof onClick === "function") {
			onClick(e);
		}
	};

	return (
		<Row className={cn} {...props} onClick={handleOnClick}>
            {iconType && <Icon type={iconType} color={iconColor} mR={3} size={28} />}
			<Label>
				{text}
			</Label>
		</Row>
	);
}

export default NavTextButton;
