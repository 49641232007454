/*
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import { Outlet, useLocation } from "react-router-dom";
import { useMemo } from "react";

import Element from "../../components/Element";
import MainHeader from "./MainHeader";

import "./MainLayout.css";

// Main Layout

function MainLayout({ className, user, ...props }) {
    const { pathname } = useLocation();

    const cn = className ? `MainLayout ${className}` : "MainLayout";

    const showHeader = useMemo(() => {
        const showHead =
            !pathname.includes("signup") && !pathname.includes("login");
        return showHead;
    }, [pathname]);

    const showDarkTheme = useMemo(
        () => pathname.includes("nav") || pathname.includes("account"),
        [pathname]
    );

    const showBottomBorder = useMemo(
        () => pathname.includes("books"),
        [pathname]
    );

    const addtMainHeaderCn = useMemo(() => {
        let mainHeaderCn = "" 
        if (showDarkTheme) mainHeaderCn += "dark-theme"
        if (!showDarkTheme) mainHeaderCn += "light-theme"
        if (showBottomBorder) mainHeaderCn += " bottom-border"
        return mainHeaderCn;
    }, [showDarkTheme, showBottomBorder]);

    return (
        <Element {...props} className={cn}>
            {showHeader && <MainHeader user={user} className={addtMainHeaderCn} />}
            <Outlet />
        </Element>
    );
}

export default MainLayout;
