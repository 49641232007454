/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";

import "./ProfilePhotoSection.css";
import UploadAvatarForm from "../../../components/forms/UploadAvatarForm";
import Avatar from "../../../components/Avatar";

// Profile Photo Section
function ProfilePhotoSection({
	className,
	loggedInUser,
	loggedInUserProfile,
	loggedInUserProfileLoading,
	...props
}) {
	const cn = className
		? `ProfilePhotoSection ${className}`
		: "ProfilePhotoSection";

	return (
		<Row className={cn} {...props}>
			<Column className="headers">
				<Label
					type={Label.Type.Heading3}
					bold
					className="section-title"
				>
					Photo
				</Label>
				<Label type={Label.Type.Body} className="section-subtitle">
					Your changes may take up to a minute to sync.
				</Label>
			</Column>
			<Column className="form-container">
                <Avatar theSrc={loggedInUser?.photoURL ?? undefined} />
				<UploadAvatarForm loggedInUser={loggedInUser} />
			</Column>
		</Row>
	);
}

export default ProfilePhotoSection;
