/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useCollection } from "react-firebase-hooks/firestore";
import { query, collection, limit } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

// useBooks hook

const useBooks = (theLimit) => {
	// Construct our books query
	const [booksQuery, setBooksQuery] = useState(null);
	const [books, setBooks] = useState([]);
	const [booksSnapshots, booksLoading, booksError] =
		useCollection(booksQuery);

	useEffect(() => {
		if (booksSnapshots) {
			const newBooks = [];
			booksSnapshots.docs.forEach((doc) => {
				newBooks.push({ doc_id: doc.id, ...doc.data() });
			});
			setBooks(newBooks);
		}
	}, [booksSnapshots]);

	// Create the query to get the books
	useEffect(() => {
		const aQuery = query(collection(db, "books"), limit(theLimit));
		setBooksQuery(aQuery);
	}, []);

	const loading = useMemo(
		() => (booksSnapshots ? booksLoading : true),
		[booksSnapshots, booksLoading]
	);

	return [books, loading, booksError];
};

export default useBooks;
