/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
  query, collection, where, getCountFromServer,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../clients/firebase';

// useIsUsernameAvailable hook

const useIsUsernameAvailable = (newUsername) => {
  // Username state
  const [username, setUsername] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);

  // UX state
  const [loading, setLoading] = useState(false);

  // Set the username
  useEffect(() => {
    if (newUsername !== username) {
      setIsAvailable(false);
      setUsername(newUsername);
    }
  }, [username, newUsername, setUsername, setIsAvailable]);

  // Query for availability
  useEffect(() => {
    const fetchStatus = async (usernameToCheck) => {
      setLoading(true);
      const q = query(
        collection(db, 'users'),
        where('username', '==', usernameToCheck),
      );
      const querySnapshot = await getCountFromServer(q);
      // Iterate the query results and determine if
      // we have an existing matching username.
      if (querySnapshot.data().count === 0) setIsAvailable(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    // Check availability if username is provided
    if (username) {
      fetchStatus(username);
    }
  }, [username]);

  return [isAvailable, loading];
};

export default useIsUsernameAvailable;
