/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import { useMemo, useState, useEffect } from "react";

import BookCover from "../../../../components/BookCover";
import Column from "../../../../components/Column";

import "./BooksColumn.css";

// Books Column
function BooksColumn({ books, className, idx, offset, ...props }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
    const cn = className ? `BooksColumn ${className}` : "BooksColumn";

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 600);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const style = useMemo(() => {
        const newStyle = {};
        if (offset) {
            newStyle.marginTop = isMobile
                ? `${offset * 0.75 * 300}px`
                : `${offset * 1.25 * 180}px`;
        }
        return newStyle;
    }, [offset, isMobile]);

    return (
        <Column key={idx} className={cn} style={style} {...props}>
            {books &&
                books.map((book, bookIdx) => (
                    <BookCover
                        animateIn
                        animateDelay={idx}
                        key={`${idx}-${book.book_id ?? book.id}`}
                        book={book}
                        imgClassName={`img-${idx}-${bookIdx}`}
                        idx={`${idx}-${bookIdx}`}
                    />
                ))}
        </Column>
    );
}

export default BooksColumn;
