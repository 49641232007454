/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// eslint-disable-next-line import/no-extraneous-dependencies
import { Accordion, AccordionItem } from "@szhsin/react-accordion";

import AnnotationsButtonPng from "../static/annotations_button.png";
import VerifyHighlightsPng from "../static/verify_highlights.png";
import SendShareButtonPng from "../static/send_share_button.png";
import AddressEmailPng from "../static/address_email.png";

import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Color from "../../../components/Color";
import Icon from "../../../components/Icon";
import Row from "../../../components/Row";

import "./AddHighlights.css";

// Vars
const EXPORT_STEPS = [
	{
		header: "1. Export your highlights",
		key: "export",
		className: "step",
		content: [
			{
				title: "a.",
				step: "Open the Kindle app and navigate to the book whose highlights you want to upload.",
				stepKey: "1",
				className: "substep",
			},
			{
				title: "b.",
				step: "Tap the bookmark icon next to the search icon in the app header to open the Annotations screen.",
				stepKey: "2",
				imageSrc: AnnotationsButtonPng,
				className: "substep",
			},
			{
				title: "c.",
				step: `Tap the send/share icon, select "Email" to export your notebook, choose "None" as the Citation Style, and hit "Export"`,
				stepKey: "3",
				imageSrc: SendShareButtonPng,
				className: "substep",
			},
		],
	},
	{
		header: "2. Email your highlights",
		key: "send",
		className: "step",
		content: [
			{
				title: "a.",
				step: "Your email client will open with a new email draft. Address it to highlights@readworthy.co. Be sure to send the email from the same address you used to create your Readworthy account! Also, don't change anything in the subject or body of the email.",
				stepKey: "1",
                imageSrc: AddressEmailPng,
				className: "substep",
			},
		],
	},
	{
		header: "3. Verify your highlights",
		key: "verify",
		className: "step",
		content: [
			{
				title: "a.",
				step: "Log in to your Readworthy account and navigate to the book within your library.",
				stepKey: "1",
				className: "substep",
			},
			{
				title: "b.",
				step: "Verify that the highlights have been uploaded.",
				stepKey: "2",
                imageSrc: VerifyHighlightsPng,
				className: "substep",
			},
		],
	},
];

// Add Highlights
function AddHighlights({ className, ...props }) {
	const cn = className ? `AddHighlights ${className}` : `AddHighlights`;

	return (
		<Column {...props} className={cn}>
			<Accordion
				allowMultiple
				className="accordian"
				transition
				transitionTimeout={250}
			>
				{EXPORT_STEPS.map(
					(
						{ header, content, className: stepClassName, key }
					) => (
						<AccordionItem
							initialEntered
							// eslint-disable-next-line react/no-unstable-nested-components
							header={({ state }) => (
								<Row
									className="header"
									justifyContent="space-between"
									alignItems="center"
									width="100%"
								>
									<Label className="step-text">
										{header}
									</Label>
									<Icon
										className={
											state.isEnter ? "up" : "down"
										}
										color={Color.Black}
										type={Icon.Type.ArrowDownLine}
										size={24}
										mR={2}
									/>
								</Row>
							)}
							key={key}
							className={stepClassName}
						>
							{content.map(
								(
									{
										step,
										stepKey,
										imageSrc,
										className: subStepClassName,
                                        title
									}
								) => (
									<>
										{imageSrc && (
											<AccordionItem
												initialEntered
												className={subStepClassName}
												// eslint-disable-next-line react/no-unstable-nested-components
												header={({ state }) => (
													<Row
														className="header"
														justifyContent="space-between"
														alignItems="center"
														width="100%"
													>
														<Row alignItems="flex-start" justifyContent="flex-start">
															<Label className="substep-text title">
																{title}
															</Label>
															<Label className="substep-text">
																{step}
															</Label>
														</Row>
														<Icon
															className={
																state.isEnter
																	? "up"
																	: "down"
															}
															color={Color.Black}
															type={
																Icon.Type
																	.ArrowDownLine
															}
															size={24}
															mR={2}
														/>
													</Row>
												)}
												key={`${step}-${stepKey}`}
											>
												{imageSrc && (
													<img
														src={imageSrc}
														alt="Annotations button location on iPhone"
													/>
												)}
											</AccordionItem>
										)}
										{!imageSrc && (
											<AccordionItem
												disabled
												className={subStepClassName}
												header={
													<Row alignItems="flex-start" justifyContent="flex-start">
															<Label className="substep-text title">
																{title}
															</Label>
															<Label className="substep-text">
																{step}
															</Label>
														</Row>
												}
												key={`${step}-${stepKey}`}
											/>
										)}
									</>
								)
							)}
						</AccordionItem>
					)
				)}
			</Accordion>
		</Column>
	);
}

export default AddHighlights;
