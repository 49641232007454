/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, collection, where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

// Book converter
const bookConverter = {
	toFirestore(post) {
		return { author: post.author, title: post.title };
	},
	fromFirestore(snapshot, options) {
		const data = snapshot.data(options);
		return {
			...data,
			id: snapshot.id,
		};
	},
};

// useBook hook
const useBook = (bookSlug) => {
	// Create state for our bookQuery and book
	const [bookQuery, setBookQuery] = useState(null);
	const [books, booksLoading, booksError] = useCollectionData(
		bookQuery,
		{},
		{ idField: "id" }
	);

	// Previous bookSlug state
	const [previousBookSlug, setPreviousBookSlug] = useState(bookSlug);

	// Construct our "book" query
	useEffect(() => {
		const collectionRef = collection(db, "books").withConverter(
			bookConverter
		);
		const aQuery = query(collectionRef, where("slug", "==", bookSlug));
		setBookQuery(aQuery);
	}, [bookSlug, previousBookSlug]);

	useEffect(() => {
		if (bookSlug !== previousBookSlug) {
			setPreviousBookSlug(bookSlug);
		}
	}, [bookSlug, previousBookSlug]);

	const book = books && books.length > 0 ? books[0] : null;
    const loading = useMemo(
		() => (books ? booksLoading : true),
		[books, booksLoading]
	);

	return [book, loading, booksError];
};

export default useBook;
