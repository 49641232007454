import { useCollection } from "react-firebase-hooks/firestore";
import { query, collection, where, orderBy } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { db } from "../clients/firebase";

const useUserBooks = (userUid) => {
    const [userBooksQuery, setUserBooksQuery] = useState(null);
    const [userBooks, setUserBooks] = useState([]);
    // const [debugInfo, setDebugInfo] = useState({});

    const [userBooksSnapshots, userBooksLoading, userBooksError] = useCollection(userBooksQuery);

    // Debug logging effect
    useEffect(() => {
        // console.group('useUserBooks Debug Info');
        // console.log('Current userUid:', userUid);
        // console.log('Query state:', userBooksQuery?.toString());
        // console.log('Loading state:', userBooksLoading);
        // console.log('Error state:', userBooksError);
        // console.log('Snapshots exist:', !!userBooksSnapshots);
        // console.log('Number of docs:', userBooksSnapshots?.docs?.length);
        // console.log('Current userBooks state:', userBooks);
        // console.groupEnd();

        // Perform a manual query to verify data
        const verifyData = async () => {
            if (userUid) {
                try {
                    // const manualQuery = query(
                    //     collection(db, "user_books"),
                    //     where("user_uid", "==", userUid)
                    // );
                    // const snapshot = await getDocs(manualQuery);
                    // // console.group('Manual Query Results');
                    // // console.log('Query path:', manualQuery);
                    // // console.log('Documents found:', snapshot.size);
                    // // snapshot.forEach(doc => {
                    // //     console.log('Document:', doc.id, doc.data());
                    // // });
                    // // console.groupEnd();
                } catch (error) {
                    console.error('Manual query error:', error);
                }
            }
        };
        verifyData();
    }, [userUid, userBooksQuery, userBooksSnapshots, userBooksLoading, userBooksError, userBooks]);

    // Update userBooks when snapshots change
    useEffect(() => {
        // console.log('Processing snapshots effect triggered');
        if (userUid && userBooksSnapshots) {
            const newUserBooks = [];
            userBooksSnapshots.docs.forEach((doc) => {
                // console.log('Processing doc:', doc.id);
                newUserBooks.push({ doc_id: doc.id, ...doc.data() });
            });
            // console.log('Setting new userBooks:', newUserBooks);
            setUserBooks(newUserBooks);
        } else if (!userUid) {
            console.log('No userUid, setting userBooks to null');
            setUserBooks(null);
        }
    }, [userUid, userBooksSnapshots]);

    // Create the query
    useEffect(() => {
        // console.log('Query creation effect triggered for userUid:', userUid);
        if (userUid) {
            const aQuery = query(
                collection(db, "user_books"),
                where("user_uid", "==", userUid),
                orderBy("created_at", "desc")
            );
            // console.log('Created query:', aQuery);
            setUserBooksQuery(aQuery);
        } else {
            // console.log('No userUid, setting query to null');
            setUserBooksQuery(null);
        }
    }, [userUid]);

    // Add debug info to loading state
    const loading = useMemo(() => {
        const isLoading = typeof userBooksSnapshots === "undefined" ? true : userBooksLoading;
        // console.log('Loading state calculated:', isLoading);
        return isLoading;
    }, [userBooksSnapshots, userBooksLoading]);

    console.log("TESTING userBooks", userBooks)
    console.log("TESTING userBooks.length", userBooks?.length)

    // Return as array to match original usage
    return [
        userBooks,
        loading,
        userBooksError,
        {  // Debug info as fourth item if needed
            userUid,
            queryExists: !!userBooksQuery,
            snapshotsExist: !!userBooksSnapshots,
            docsCount: userBooksSnapshots?.docs?.length,
            isLoading: loading
        }
    ];
};

export default useUserBooks;