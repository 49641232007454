/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
// eslint-disable-next-line import/no-extraneous-dependencies
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-loading-skeleton/dist/skeleton.css";

import Element from "../../../../components/Element";
import Loading from "../../../../components/Loading";
import Column from "../../../../components/Column";
import Button from "../../../../components/Button";
import Row from "../../../../components/Row";

import "./ProductLoading.css";

// Product Loading
function ProductLoading({ ...props }) {
	return (
		<SkeletonTheme>
			<Column className="ProductLoading" {...props}>
				<Row className="product-header">
					<Skeleton width="200px" />
				</Row>
				<Element as="ul">
					{[0,1,2].map((num) => (
						<Row
							className="feature"
							key={num}
							alignItems="flex-start"
						>
                            <Skeleton width="20px" style={{ marginRight: "10px"}} />
							<Skeleton width="260px" count={num === 2 ? 2 : 1} />
						</Row>
					))}
				</Element>
				<Row className="product-footer">
						<Button
							className="checkout"
						>
							<Row pT={2} pB={2}>
								<Loading colorClassName="transparent" />
							</Row>
						</Button>
				</Row>
			</Column>
		</SkeletonTheme>
	);
}

export default ProductLoading;
