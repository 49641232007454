/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Element from '../Element';

import './Button.css';

// Button

function Button({
  children,
  className,
  disabled,
  justifyContent,
  alignItems,
  onClick,
  type="button",
  ...props
}) {
  const cn = className ? `Button ${className}` : 'Button';

  // Add styles
  const styles = {};
  if (justifyContent) {
    styles.justifyContent = justifyContent;
  }
  if (alignItems) {
    styles.alignItems = alignItems;
  }

  return (
    <Element
      {...props}
      as="button"
      type={type}
      disabled={disabled}
      className={cn}
      style={styles}
      onClick={onClick}
    >
      {children}
    </Element>
  );
}

export default Button;
