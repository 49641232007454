/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import "./BuiltBy.css";

// Built By
function BuiltBy({ className }) {
    const cn = className ? `BuiltBy ${className}` : "BuiltBy";

    return <a href="https://x.com/standardio" rel="noreferrer" target="_blank" className={cn}>by @standardio</a>;
}

export default BuiltBy;
