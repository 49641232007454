/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import {
	RiAtLine,
	RiDeleteBinLine,
	RiPencilLine,
	RiMoreFill,
	RiTiktokFill,
	RiGoogleFill,
	RiFacebookFill,
	RiGithubFill,
	RiInstagramFill,
	RiTwitterFill,
	RiYoutubeFill,
	RiSnapchatFill,
	RiPinterestFill,
	RiLinkedinBoxFill,
	RiExternalLinkLine,
    RiLink,
	RiRestartLine,
	RiSendPlane2Line,
	RiAddCircleLine,
	RiAccountCircleLine,
	RiCloseFill,
	RiSaveLine,
	RiArrowRightLine,
	RiArrowLeftLine,
	RiCloseCircleLine,
	RiCheckboxCircleFill,
	RiHome5Line,
	RiHome5Fill,
	RiLogoutBoxLine,
	RiAddLine,
	RiMoneyDollarCircleLine,
	RiCheckLine,
	RiBookMarkLine,
    RiBookOpenFill,
	RiBardFill,
	RiArrowDownSLine,
	RiFileCopyLine,
    RiListOrdered2,
    RiListSettingsLine,
    RiShareForwardFill
} from "react-icons/ri";
import { MdOutlineFormatListNumberedRtl } from "react-icons/md";
import { FaHighlighter } from "react-icons/fa";
import { TbArrowBigLeftFilled } from "react-icons/tb";

import "./Icon.css";

// Icon

const Icon = ({
	color,
	type,
	className,
	size,
	mL,
	mR,
	mT,
	mB,
    touchable,
	...props
}) => {
	let cn = className ? `Icon ${className}` : "Icon";
	if (color) cn += ` ${color}`;
	const styles = {};

	// Sizes
	if (size) {
		styles.fontSize = `${size}px`;
		styles.width = `${size}px`;
		styles.height = `${size}px`;
	}

	// Margins
	if (mB) {
		cn += ` mB-${mB}`;
	}
	if (mT) {
		cn += ` mT-${mT}`;
	}
	if (mL) {
		cn += ` mL-${mL}`;
	}
	if (mR) {
		cn += ` mR-${mR}`;
	}

    // Touchable
    if (touchable) {
        cn += ' touchable'
    }

	let Klass;
	if (type) {
		switch (type) {
			// Socials
			case "google":
				Klass = (
					<RiGoogleFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "instagram":
				Klass = (
					<RiInstagramFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "facebook":
				Klass = (
					<RiFacebookFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "twitter":
				Klass = (
					<RiTwitterFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "youtube":
				Klass = (
					<RiYoutubeFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "github":
				Klass = (
					<RiGithubFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "tiktok":
				Klass = (
					<RiTiktokFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "snapchat":
				Klass = (
					<RiSnapchatFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "pinterest":
				Klass = (
					<RiPinterestFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "linkedin":
				Klass = (
					<RiLinkedinBoxFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			// Other
			case "trash":
				Klass = (
					<RiDeleteBinLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "edit":
				Klass = (
					<RiPencilLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "pencil":
				Klass = (
					<RiPencilLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "add-circle-outline":
				Klass = (
					<RiAddCircleLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "account-circle-outline":
				Klass = (
					<RiAccountCircleLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "add-line":
				Klass = (
					<RiAddLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "close":
				Klass = (
					<RiCloseFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "credit-card":
				Klass = (
					<RiMoneyDollarCircleLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "home-filled":
				Klass = (
					<RiHome5Fill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "home-outline":
				Klass = (
					<RiHome5Line
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "logout-outline":
				Klass = (
					<RiLogoutBoxLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "at":
				Klass = (
					<RiAtLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "three-dots-horizontal":
				Klass = (
					<RiMoreFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "external-link":
				Klass = (
					<RiExternalLinkLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
            case "link":
                Klass = (
                    <RiLink
                        size={size}
                        {...props}
                        style={styles}
                        className={cn}
                    />
                );
                break;
			case "restart":
				Klass = (
					<RiRestartLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "send":
				Klass = (
					<RiSendPlane2Line
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "save":
				Klass = (
					<RiSaveLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "checkmark-circle-filled":
				Klass = (
					<RiCheckboxCircleFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "check-line":
				Klass = (
					<RiCheckLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "close-circle-line":
				Klass = (
					<RiCloseCircleLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			// Arrows
			case "line-arrow-right":
				Klass = (
					<RiArrowRightLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "line-arrow-left":
				Klass = (
					<RiArrowLeftLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "arrow-left-thick":
				Klass = (
					<TbArrowBigLeftFilled
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
            case "book-marked-fill":
                Klass = (
                    <RiBookOpenFill
                        size={size}
                        {...props}
                        style={styles}
                        className={cn}
                    />
                );
                break;
			case "book-mark-line":
				Klass = (
					<RiBookMarkLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "bard-fill":
				Klass = (
					<RiBardFill
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "arrow-down-line":
				Klass = (
					<RiArrowDownSLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "highlighter":
				Klass = (
					<FaHighlighter
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "outline-format-list-numbered":
				Klass = (
					<MdOutlineFormatListNumberedRtl
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
			case "file-copy-line":
				Klass = (
					<RiFileCopyLine
						size={size}
						{...props}
						style={styles}
						className={cn}
					/>
				);
				break;
            case "list-ordered-2":
                Klass = (
                    <RiListOrdered2
                        size={size}
                        {...props}
                        style={styles}
                        className={cn}
                    />
                );
                break;
            case "list-settings-line":
                Klass = (
                    <RiListSettingsLine
                        size={size}
                        {...props}
                        style={styles}
                        className={cn}
                    />
                );
                break;
            case "share2-line":
                Klass = (
                    <RiShareForwardFill
                        size={size}
                        {...props}
                        style={styles}
                        className={cn}
                    />
                );
                break;
			default:
				break;
		}
	}
	return Klass;
};

Icon.Type = {
	// Socials
	Google: "google",
	Instagram: "instagram",
	Facebook: "facebook",
	Twitter: "twitter",
	Youtube: "youtube",
	Github: "github",
	Tiktok: "tiktok",
	Snapchat: "snapchat",
	Pinterest: "pinterest",
	Linkedin: "linkedin",
	// Other
	AddCircleOutline: "add-circle-outline",
	AccountCircleLine: "account-circle-outline",
	AddLine: "add-line",
	At: "at",
	Close: "close",
	CreditCard: "credit-card",
	HomeOutline: "home-outline",
	HomeFilled: "home-filled",
	LogoutOutline: "logout-outline",
	Trash: "trash",
	Edit: "edit",
	Delete: "trash",
	Pencil: "pencil",
	ThreeDotsHorizontal: "three-dots-horizontal",
	ExternalLink: "external-link",
	Link: "link",
	Restart: "restart",
	Send: "send",
	Save: "save",
	LineArrowRight: "line-arrow-right",
	LineArrowLeft: "line-arrow-left",
	ArrowLeftThick: "arrow-left-thick",
	CheckLine: "check-line",
	CheckmarkCircleFilled: "checkmark-circle-filled",
	CloseCircleLine: "close-circle-line",
	BookMarkLine: "book-mark-line",
	BookMarkedFill: "book-marked-fill",
	BardFill: "bard-fill",
	ArrowDownLine: "arrow-down-line",
	Highlighter: "highlighter",
	OutlineFormatListNumbered: "outline-format-list-numbered",
	Copy: "file-copy-line",
    ListOrdered2: "list-ordered-2",
    RiListSettingsLine: "list-settings-line",
    Share: "share2-line"
};

export default Icon;
