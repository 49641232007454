/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useParams } from "react-router-dom";

import { useEffect, useMemo } from "react";

import MaxWidthContainer from "../../components/MaxWidthContainer";
import BookHeader from "../../components/BookHeader";
import Column from "../../components/Column";
import Page from "../../components/Page";

import { getBookInfo } from "../../utils/books";

import useBook from "../../hooks/useBook";

import "./Reader.css";
import PageTitles from "../../components/PageTitles";

// Reader
function Reader({ loggedInUser }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Get the user's displayName, and the book's slug
	const { book_slug: bookSlug, username: bookOwnerDisplayName } = useParams();

	// Get the book
	const [book] = useBook(bookSlug);

	// Format some info from the book
	const { authorsNames, title, description } = useMemo(() => {
		const {
			title: bookTitle,
			subtitle: bookSubtitle,
			authorsNames: bookAuthorsNames,
			description: bookDescription,
		} = getBookInfo(book);
		return {
			title: bookTitle,
			subtitle: bookSubtitle,
			authorsNames: bookAuthorsNames,
			description: bookDescription,
		};
	}, [book]);

	const massagedDescription = useMemo(() => {
		let result = description;
		if (description && description.endsWith("Read more")) {
			result = description.replace("Read more", "");
		}
		return result;
	}, [description]);

	const { pageTitle, pageSubtitle } = useMemo(() => {
		const theTitle = "Summary";
		const theSubtitle = `${title} by ${authorsNames}`;
		return { pageTitle: theTitle, pageSubtitle: theSubtitle };
	}, [title, authorsNames]);

	return (
		<Page className="Reader">
			<BookHeader
				kind="reader"
				loggedInUser={loggedInUser}
				usernameParam={bookOwnerDisplayName}
				bookSlugParam={bookSlug}
			/>
			<MaxWidthContainer className="content">
				<PageTitles title={pageTitle} subtitle={pageSubtitle} />
				<Column
					className="text"
					pL={4}
					pR={4}
					pT={8}
					dangerouslySetInnerHTML={{ __html: massagedDescription }}
				/>
			</MaxWidthContainer>
		</Page>
	);
}

export default Reader;
