/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import Label from "../Label";
import MaxWidthContainer from "../MaxWidthContainer";
import Row from "../Row";

import "./PageTitles.css";

// PageTitles
function PageTitles({ title, subtitle, className, ...props }) {
	const cn = className ? `PageTitles ${className}` : "PageTitles";

	return (
		<Row className={cn} {...props}>
			<MaxWidthContainer>
				<Label type={Label.Type.Heading1} className="title">
					{title}
				</Label>
				<Label type={Label.Type.Heading5} className="subtitle">
					{subtitle}
				</Label>
			</MaxWidthContainer>
		</Row>
	);
}

export default PageTitles;
