/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import { useParams } from "react-router-dom";

import { useEffect, useMemo } from "react";

import MaxWidthContainer from "../../components/MaxWidthContainer";
import BookHeader from "../../components/BookHeader";
import PageTitles from "../../components/PageTitles";
import Column from "../../components/Column";
import Page from "../../components/Page";

import AddHighlights from "./AddHighlights";
import Highlight from "./Highlight";

import { getBookInfo } from "../../utils/books";

import useUserBookHighlights from "../../hooks/useUserBookHighlights";
import useBook from "../../hooks/useBook";

import "./HighlightsPage.css";

// Highlights Page
function HighlightsPage({ loggedInUser }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Get the user's displayName, and the book's slug
	const { book_slug: bookSlug, username: bookOwnerDisplayName } = useParams();

	// Get the book
	const [book] = useBook(bookSlug);

	// Format some info from the book
	const { title, authorsNames } = useMemo(() => {
		const {
			title: bookTitle,
			subtitle: bookSubtitle,
			authorsNames: bookAuthorsNames,
			description: bookDescription,
		} = getBookInfo(book);
		return {
			title: bookTitle,
			subtitle: bookSubtitle,
			authorsNames: bookAuthorsNames,
			description: bookDescription,
		};
	}, [book]);

	const userBookId = useMemo(
		() => (book && loggedInUser ? `${loggedInUser.uid}_${book.id}` : null),
		[loggedInUser, book]
	);

	const [highlights, loadingHighlights] = useUserBookHighlights(userBookId);

	// Show the highlights or the add highlights content
	const { showHighlights, showAddHighlights } = useMemo(() => {
		const showHigh =
			highlights && highlights.length > 0 && !loadingHighlights;
		const showAddHigh =
			(!highlights || highlights?.length === 0) && !loadingHighlights;
		return { showHighlights: showHigh, showAddHighlights: showAddHigh };
	}, [highlights, loadingHighlights]);

	// Create the page title and subtitle
	const { pageTitle, pageSubtitle } = useMemo(() => {
		let theTitle = `${bookOwnerDisplayName}'s highlights`;
		let theSubtitle = `from ${title} by ${authorsNames}`;
		if (showAddHighlights) {
			theTitle = "Add highlights";
			theSubtitle =
				"Follow these steps to upload your Kindle highlights:";
		}
		return { pageTitle: theTitle, pageSubtitle: theSubtitle };
	}, [bookOwnerDisplayName, title, authorsNames, showAddHighlights]);

	const sortedHighlights = useMemo(
		() => highlights?.sort((a, b) => a.location - b.location),
		[highlights]
	);

	// Classname for outermost component
	const cn = useMemo(
		() => (showAddHighlights ? "HighlightsPage WithoutHighlights" : "HighlightsPage WithHighlights"),
		[showAddHighlights]
	);

	return (
		<Page className={cn}>
			<BookHeader
				kind="highlights"
				loggedInUser={loggedInUser}
				usernameParam={bookOwnerDisplayName}
				bookSlugParam={bookSlug}
			/>
			<MaxWidthContainer className="content">
				<PageTitles title={pageTitle} subtitle={pageSubtitle} />
				{showHighlights && (
					<Column className="highlights" pL={4} pR={4} pT={8}>
						{sortedHighlights.map((highlight) => (
							<Highlight
								key={highlight.hash}
								highlight={highlight}
								mB={6}
							/>
						))}
					</Column>
				)}
				{showAddHighlights && (
					<Column className="add-highlights" pL={4} pR={4} pT={8} pB={20}>
						{showAddHighlights && <AddHighlights />}
					</Column>
				)}
			</MaxWidthContainer>
		</Page>
	);
}

export default HighlightsPage;
