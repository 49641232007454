/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import MaxWidthContainer from "../../components/MaxWidthContainer";
import Column from "../../components/Column";
import NavTextButton from "./NavTextButton";
import Page from "../../components/Page";
import Icon from "../../components/Icon";
import NavSection from "./NavSection";
import NavLink from "./NavLink";

import "./NavPage.css";

// Nav Page
function NavPage({ loggedInUser, loggedInUserIsPaid }) {
	const handleOnSignOut = () => {};

	return (
		<Page className="NavPage">
			<MaxWidthContainer>
				<Column className="content">
					<nav>
						<NavSection>
							<NavLink
								text="Account settings"
								activeIconType={Icon.Type.HomeFilled}
								inactiveIconType={Icon.Type.AccountCircleLine}
								to={`/${loggedInUser?.displayName}/account/`}
								mT={3}
							/>
							<NavLink
								text="Bookshelf"
								activeIconType={Icon.Type.HomeFilled}
								inactiveIconType={Icon.Type.HomeOutline}
								to={`/${loggedInUser?.displayName}`}
								mT={3}
							/>
							{loggedInUserIsPaid && (
								<NavTextButton
									iconType={Icon.Type.CreditCard}
									text="Manage subscription"
									onClick={handleOnSignOut}
									mT={3}
								/>
							)}
							<NavTextButton
								iconType={Icon.Type.LogoutOutline}
								text="Sign out"
								onClick={handleOnSignOut}
								mT={3}
							/>
						</NavSection>
					</nav>
				</Column>
			</MaxWidthContainer>
		</Page>
	);
}

export default NavPage;
