/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports
import Column from "../../../../components/Column";
import Color from "../../../../components/Color";
import Label from "../../../../components/Label";
import Icon from "../../../../components/Icon";

import "./EmptyBook.css";

// Empty Book
function EmptyBook({ className, onClick, ...props }) {
	const cn = className ? `EmptyBook ${className}` : "EmptyBook";

	const handleOnClick = () => {
		if (typeof onClick === "function") {
			onClick();
		}
	};

	return (
		<Column onClick={handleOnClick} className={cn} {...props}>
			<Column className="cover">
                <Icon type={Icon.Type.BookMarkLine} color={Color.Black} size={28} />
                <Label bold mT={3}>Tap to add your first book</Label>
            </Column>
		</Column>
	);
}

export default EmptyBook;
