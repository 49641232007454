/**
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { collection, query, where, orderBy, limit, startAfter, getDocs } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { db } from "../clients/firebase"; // Adjust this import as needed

const usePaginateCollection = (
    collectionTitle,
    whereClause,
    orderField,
    orderDir,
    initialPageSize = 21
  ) => {
    const [queryParams, setQueryParams] = useState({
      title: collectionTitle,
      where: whereClause,
      field: orderField,
      dir: orderDir,
      pageSize: initialPageSize,
      currentPage: 1,
    });
  
    const [pageHistory, setPageHistory] = useState([null]); // Stores the last document of each page
  
    useEffect(() => {
      setQueryParams({
        title: collectionTitle,
        where: whereClause,
        field: orderField,
        dir: orderDir,
        pageSize: initialPageSize,
        currentPage: 1,
      });
      setPageHistory([null]);
    }, [collectionTitle, whereClause, orderField, orderDir, initialPageSize]);
  
    const constraints = useMemo(() => {
      const constraintsList = [];
      if (queryParams.where) {
        const [field, operator, value] = queryParams.where;
        constraintsList.push(where(field, operator, value));
      }
      if (queryParams.field && queryParams.dir) {
        constraintsList.push(orderBy(queryParams.field, queryParams.dir));
      }
      constraintsList.push(limit(queryParams.pageSize));
      if (pageHistory[queryParams.currentPage - 1]) {
        constraintsList.push(startAfter(pageHistory[queryParams.currentPage - 1]));
      }
      return constraintsList;
    }, [queryParams, pageHistory]);
  
    const firestoreQuery = useMemo(() => 
      query(collection(db, queryParams.title), ...constraints),
      [queryParams.title, constraints]
    );
  
    const [results, loading, error,, reload] = useCollectionDataOnce(firestoreQuery);
  
    useEffect(() => {
      if (firestoreQuery) {
        reload();
      }
    }, [firestoreQuery, reload]);
  
    const goToNextPage = useCallback(async () => {
      if (results && results.length === queryParams.pageSize) {
        const lastVisible = results[results.length - 1];
        setPageHistory(prev => [...prev.slice(0, queryParams.currentPage), lastVisible]);
        setQueryParams(prev => ({ ...prev, currentPage: prev.currentPage + 1 }));
      }
    }, [results, queryParams]);
  
    const goToPreviousPage = useCallback(() => {
      if (queryParams.currentPage > 1) {
        setQueryParams(prev => ({ ...prev, currentPage: prev.currentPage - 1 }));
      }
    }, [queryParams.currentPage]);
  
    const jumpToPage = useCallback(async (targetPage) => {
      const validatedPage = Math.max(1, targetPage);
  
      if (validatedPage <= pageHistory.length) {
        // We have this page in history, so we can jump directly
        setQueryParams(prev => ({ ...prev, currentPage: validatedPage }));
      } else {
        // We need to fetch all pages up to this one
        let lastDoc = pageHistory[pageHistory.length - 1];
        let currentPage = pageHistory.length;
        const newHistory = [...pageHistory];
  
        for (let i = currentPage; i < validatedPage; i+=1) {
          const nextPageQuery = query(
            collection(db, queryParams.title),
            ...constraints,
            startAfter(lastDoc)
          );
  
          // eslint-disable-next-line no-await-in-loop
          const snapshot = await getDocs(nextPageQuery);
          if (snapshot.empty) {
            // We've reached the end of the collection
            break;
          }
  
          lastDoc = snapshot.docs[snapshot.docs.length - 1];
          newHistory.push(lastDoc);
          currentPage = i + 1;
        }
  
        setPageHistory(newHistory);
        setQueryParams(prev => ({ ...prev, currentPage }));
      }
    }, [queryParams, constraints, pageHistory]);
  
    const setPageSize = useCallback((newPageSize) => {
      setQueryParams(prev => ({ ...prev, pageSize: newPageSize, currentPage: 1 }));
      setPageHistory([null]);
    }, []);
  
    return {
      results,
      loading,
      error,
      currentPage: queryParams.currentPage,
      pageSize: queryParams.pageSize,
      goToNextPage,
      goToPreviousPage,
      jumpToPage,
      setPageSize,
    };
  };

export default usePaginateCollection;


