/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { Navigate, Outlet } from 'react-router-dom';

// Protected Route

function ProtectedRoute({
  isPublic,
  isAuthorized,
  children,
  redirectPath = '/login',
}) {
  return isPublic || isAuthorized
    ? (
      children ?? <Outlet />
    )
    : (
      <Navigate to={redirectPath} replace />
    );
}

export default ProtectedRoute;
