/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import Label from "../../../components/Label";
import "./NavSectionTitle.css";

// Nav Section Title

function NavSectionTitle({ title, className, ...props }) {

    const cn = className ? `NavSectionTitle ${className}` : `NavSectionTitle`

	return <Label className={cn} {...props} />
}

export default NavSectionTitle;
