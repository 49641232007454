/*
 * Copyright (C) 2023 Andrew Burnett <questions@dubitoergo.com>
 */

// Imports

import Element from '../Element';

import './Row.css';

// Row

function Row({
  children, className, justifyContent, alignItems, ...props
}) {
  const cn = className ? `Row ${className}` : 'Row';

  // Add styles
  const styles = {};
  if (justifyContent) {
    styles.justifyContent = justifyContent;
  }
  if (alignItems) {
    styles.alignItems = alignItems;
  }

  return (
    <Element className={cn} style={styles} {...props}>
      {children}
    </Element>
  );
}

export default Row;
