/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { Toaster } from 'react-hot-toast';

// Custom Toaster

function CustomToaster() {
  return (
    <Toaster
      toastOptions={{
			  // Default options for specific types
			  error: {
			    style: {
			      background: 'var(--light-red-color)',
			      border: '1px solid var(--red-color)',
			      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
			      fontSize: '15px',
			      padding: 'var(--spacing-4x)',
			    },
			  },
      }}
      style={{ zIndex: '100000' }}
    />
  );
}

export default CustomToaster;
