/**
 * Copyright (C) 2024 Andrew Burnett <questions@dubitoergo.com>
 *
 * @format
 */

// Imports

import { Link } from "react-router-dom";

import Column from "../../../components/Column";
import Label from "../../../components/Label";
import Row from "../../../components/Row";

import "./AnonymousFooter.css";
import MaxWidthContainer from "../../../components/MaxWidthContainer";

// Anonymous Footer

function AnonymousFooter({ className }) {
    const cn = className ? `AnonymousFooter ${className}` : "AnonymousFooter";

    return (
        <Row className={cn}>
            <MaxWidthContainer>
                <Column className="info">
                    <Label className="app-name">Readworthy</Label>
                    <Label className="copyright">
                        © 2024 Readworthy. All rights reserved.{" "} Built with ❤️ & support from <a href="https://www.instagram.com/montana.cen" target="_blank" rel="noreferrer">Chicken</a>.
                    </Label>
                </Column>
                <Row className="links">
                    <Column>
                        <Label className="header">Expert Recommendations</Label>
                        <Row className="experts">
                            <Column className="one">
                                <Link
                                    className="expert-link"
                                    to="/elonmusk"
                                >
                                    Elon Musk
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/navalravikant"
                                >
                                    Naval Ravikant
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/billgates"
                                >
                                    Bill Gates
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/barackobama"
                                >
                                    Barack Obama
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/timferris"
                                >
                                    Tim Ferris
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/joerogan"
                                >
                                    Joe Rogan
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/jordanpeterson"
                                >
                                    Jordan Peterson
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/warrenbuffett"
                                >
                                    Warren Buffett
                                </Link>

                                <Link
                                    className="expert-link"
                                    to="/charliemunger"
                                >
                                    Charlie Munger
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/ryanholiday"
                                >
                                    Ryan Holiday
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/ariannahuffington"
                                >
                                    Arianna Huffington
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/richardbranson"
                                >
                                    Richard Branson
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/oprahwinfrey"
                                >
                                    Oprah Winfrey
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/jeffbezos"
                                >
                                    Jeff Bezos
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/ericweinstein"
                                >
                                    Eric Weinstein
                                </Link>
                            </Column>
                            <Column className="two">
                                <Link
                                    className="expert-link"
                                    to="/raydalio"
                                >
                                    Ray Dalio
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/jockowillink"
                                >
                                    Jocko Willink
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/anthonypompliano"
                                >
                                    Anothony Pompliano
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/keithrabois"
                                >
                                    Keith Rabois
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/markzuckerberg"
                                >
                                    Mark Zuckerberg
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/stevejobs"
                                >
                                    Steve Jobs
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/samaltman"
                                >
                                    Sam Altman
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/peterthiel"
                                >
                                    Peter Thiel
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/nassimtaleb"
                                >
                                    Nassim Taleb
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/marlcomgladwell"
                                >
                                    Malcom Gladwell
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/daviddeutsch"
                                >
                                    David Deutsch
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/peterattia"
                                >
                                    Peter Attia
                                </Link>

                                <Link
                                    className="expert-link"
                                    to="/hillaryclinton"
                                >
                                    Hillary Clinton
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/brianarmstrong"
                                >
                                    Brian Armstrong
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/patrickcollison"
                                >
                                    Patrick Collison
                                </Link>
                            </Column>
                            <Column className="three">
                                <Link
                                    className="expert-link"
                                    to="/jackdorsey"
                                >
                                    Jack Dorsey
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/tobilutke"
                                >
                                    Tobi Lutke
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/marcandreessen"
                                >
                                    Marc Andreessen
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/markcuban"
                                >
                                    Mark Cuban
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/melindagates"
                                >
                                    Melina Gates
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/donaldtrump"
                                >
                                    Donald Trump
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/ericweinstein"
                                >
                                    EricWeinstein
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/tonyrobbins"
                                >
                                    Tony Robbins
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/jkrowling"
                                >
                                    J.K. Rowling
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/balajisrinivasan"
                                >
                                    Balaji Srinivasan
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/michaelpollan"
                                >
                                    Michael Pollan
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/maxlevchin"
                                >
                                    Max Levchin
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/billgurley"
                                >
                                    Bill Gurley
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/charleskoch"
                                >
                                    Charles Koch
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/benhorowitz"
                                >
                                    Ben Horowitz
                                </Link>
                            </Column>
                        </Row>
                    </Column>
                    <Column>
                        <section className="pages">
                            <Label className="header">Pages</Label>
                            <Column className="one">
                                <Link className="expert-link" to="/people">
                                    Experts
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/signup/username"
                                >
                                    Sign up
                                </Link>
                                <Link className="expert-link" to="/login">
                                    Log in
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="/billing"
                                >
                                    Billing
                                </Link>
                            </Column>
                        </section>
                        <section className="other-sites">
                            <Label className="header" mT={12}>Other Sites</Label>
                            <Column className="one">
                                <Link
                                    className="expert-link"
                                    to="https://stuf.dev"
                                >
                                    Stuf.Dev
                                </Link>
                                <Link
                                    className="expert-link"
                                    to="https://standard-output.com/"
                                >
                                    Standard Output
                                </Link>
                            </Column>
                        </section>
                    </Column>
                </Row>
            </MaxWidthContainer>
        </Row>
    );
}

export default AnonymousFooter;
